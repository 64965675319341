import React, { Component } from "react";
import { Col, Row, Card, CardImg } from "reactstrap";

import imgRumo from "../../assets/images/rumo.png";
import imgHoras from "../../assets/images/horas.png";
import imgRecursos from "../../assets/images/recursos.png";
import imgNetwork from "../../assets/images/network.png";
import imgModelagem from "../../assets/images/modelagem.png";
import imgConhecimento from "../../assets/images/conhecimento.png";
import img12areas from "../../assets/images/12areas.png";
import imgScore from "../../assets/images/score.png";
import imgSuporte from "../../assets/images/suporte.png";
import imgTelegram from "../../assets/images/telegram.png";
import imgTreinamento from "../../assets/images/treinamento.png";

import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems : [
            ],
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Breadcrumbs title="" breadcrumbItems={this.state.breadcrumbItems} />
                    <Row>
                        <Col mg={6} xl={3}>
                            <Card style={{ borderRadius: '25px' }}>
                                <Link 
                                    to="/rumo"
                                >
                                    <CardImg top className="img-fluid" src={imgRumo} alt="O Rumo" style={{ borderRadius: '25px' }}/>
                                </Link>
                            </Card>
                        </Col>
                        <Col mg={6} xl={3}>
                            <Card style={{ borderRadius: '25px' }}>
                                <Link 
                                    to="/horas"
                                >
                                    <CardImg top className="img-fluid" src={imgHoras} alt="Horas" style={{ borderRadius: '25px' }}/>
                                </Link>
                            </Card>
                        </Col>
                        <Col mg={6} xl={3}>
                            <Card style={{ borderRadius: '25px' }}>
                                <Link 
                                    to="/recursos"
                                >
                                    <CardImg top className="img-fluid" src={imgRecursos} alt="Gestão de Recursos" style={{ borderRadius: '25px' }}/>
                                </Link>
                            </Card>
                        </Col>
                        <Col mg={6} xl={3}>
                            <Card style={{ borderRadius: '25px' }}>
                                <Link 
                                    to="/network"
                                >
                                    <CardImg top className="img-fluid" src={imgNetwork} alt="Network" style={{ borderRadius: '25px' }}/>
                                </Link>
                            </Card>
                        </Col>
                        <Col mg={6} xl={3}>
                            <Card style={{ borderRadius: '25px' }}>
                                <Link 
                                    to="/modelagem"
                                >
                                    <CardImg top className="img-fluid" src={imgModelagem} alt="Modelagem" style={{ borderRadius: '25px' }}/>
                                </Link>
                            </Card>
                        </Col>
                        <Col mg={6} xl={3}>
                            <Card style={{ borderRadius: '25px' }}>
                                <Link 
                                    to="/conhecimento"
                                >
                                    <CardImg top className="img-fluid" src={imgConhecimento} alt="Conhecimento" style={{ borderRadius: '25px' }}/>
                                </Link>
                            </Card>
                        </Col>
                        <Col mg={6} xl={3}>
                            <Card style={{ borderRadius: '25px' }}>
                                <Link 
                                    to="#"
                                >
                                    <CardImg top className="img-fluid" src={img12areas} alt="12 Áreas da vida" style={{ borderRadius: '25px' }}/>
                                </Link>
                            </Card>
                        </Col>
                        <Col mg={6} xl={3}>
                            <Card style={{ borderRadius: '25px' }}>
                                <Link 
                                    to="#"
                                >
                                    <CardImg top className="img-fluid" src={imgScore} alt="Score" style={{ borderRadius: '25px' }}/>
                                </Link>
                            </Card>
                        </Col>
                        <Col mg={6} xl={3}>
                            <Card style={{ borderRadius: '25px' }}>
                                <Link to={{ pathname: "https://www.instagram.com/janleysoares/" }} target="_blank">
                                    <CardImg top className="img-fluid" src={imgSuporte} alt="Suporte" style={{ borderRadius: '25px' }}/>
                                </Link>
                            </Card>
                        </Col>
                        <Col mg={6} xl={3}>
                            <Card style={{ borderRadius: '25px' }}>
                                <Link to={{ pathname: "https://t.me/ojogodavida2023" }} target="_blank">
                                    <CardImg top className="img-fluid" src={imgTelegram} alt="Telegram" style={{ borderRadius: '25px' }}/>
                                </Link>
                            </Card>
                        </Col>
                        <Col mg={6} xl={3}>
                            <Card style={{ borderRadius: '25px' }}>
                                <Link to="/treinamento">
                                    <CardImg top className="img-fluid" src={imgTreinamento} alt="Treinamento" style={{ borderRadius: '25px' }}/>
                                </Link>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

export default Dashboard;
