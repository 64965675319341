import React, { Component } from "react";

import { Row, Col, Card, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Table } from "reactstrap";
import { Link } from "react-router-dom";
import moment from 'moment';

import { DateRangePicker, Stack, CustomProvider } from 'rsuite';
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import { ToastContainer, toast } from 'react-toastify';

import ToolkitProvider from 'react-bootstrap-table2-toolkit';

import api from '../../../services/api';

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import "../../../assets/scss/datatables.scss";

const predefinedRanges = [
  {
    label: 'Hoje',
    value: [new Date(), new Date()],
    placement: 'left'
  },
  {
    label: 'Ontem',
    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
    placement: 'left'
  },
  {
    label: 'Essa semana',
    value: [startOfWeek(new Date()), endOfWeek(new Date())],
    placement: 'left'
  },
  {
    label: 'Últimos 7 dias',
    value: [subDays(new Date(), 6), new Date()],
    placement: 'left'
  },
  {
    label: 'Últimos 30 dias',
    value: [subDays(new Date(), 29), new Date()],
    placement: 'left'
  },
  {
    label: 'Mês passado',
    value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
    placement: 'left'
  },
  {
    label: 'Mês atual',
    value: [startOfMonth(new Date()), endOfMonth(new Date())],
    placement: 'left'
  },
  {
    label: 'Ano atual',
    value: [new Date(new Date().getFullYear(), 0, 1), new Date(new Date().getFullYear(), 11, 31)],
    placement: 'left'
  }
];

class Agenda extends Component {
  
    constructor(props) {
      super(props)
      this.state = {
        breadcrumbItems: [
          { title: "Menu", link: "/dashboard" },
          { title: "Relatório", link: "#" },
        ],
        page: 1,
        sizePerPage: 10,
        productData: [],
        totalData: [],
        acaoData: [],
        listapresenca: [],
        visible: false,
        modal_standard: false,
        modal_apagar: false,
        modal_acao: false,
        filtro: '',
        url: '/eventos',
        instagram: 0,
        telefone: 0,
        email: 0,
        whatsapp: 0,
        eventosTotal: 0
      }

      this.controlarModal = this.controlarModal.bind(this);
      this.controlarModalLista = this.controlarModalLista.bind(this);
    };

    async buscarDados() {
      const options = this.getOptions();

      let filtro = [];
  
      const range = document.getElementById('calendario').value.replace(/-/g, '/').split(' ~ ');
      if (range) {
        filtro = range;
      }

      let inicio = '';
      let fim = '';

      if (filtro[0] && filtro[1]) {
        inicio = filtro[0];
        fim = filtro[1];
      }

      const { data } = await api.get(`${this.state.url}?inicio=${inicio}&fim=${fim}`, options);

      this.setState({
        productData: data,
        eventosTotal: data.length
      });

      this.buscarDadosTotal();
    }

    async buscarDadosTotal() {
      const options = this.getOptions();

      let filtro = [];
  
      const range = document.getElementById('calendario').value.replace(/-/g, '/').split(' ~ ');
      if (range) {
        filtro = range;
      }

      let inicio = '';
      let fim = '';

      if (filtro[0] && filtro[1]) {
        inicio = filtro[0];
        fim = filtro[1];
      }

      const { data } = await api.get(`${this.state.url}/total?inicio=${inicio}&fim=${fim}`, options);

      this.setState({
        totalData: data
      });
    }

    setTotal(data) {
      if (data.status === 'Fazer Contato') {
        this.setState({
          fazercontato: data.total
        });
        return;
      }
    }

    setZerarTotal() {
      this.setState({
        fazercontato: 0,
      })
    }

    pesquisar() {
      const valorPesquisa = this.getValueId('valorPesquisar');
      
      if (document.getElementsByClassName("page-link").length > 0) {
        document.getElementsByClassName("page-link")[0].click();
      }
      
      this.setState({
        filtro: valorPesquisa
      });

      this.buscarDados();
    }

    getOptions() {
        let obj = '';
        if (localStorage.getItem("authUser")) {
        obj = JSON.parse(localStorage.getItem("authUser"));
        }

        const options = {
        headers: {"Authorization" : `Bearer ${obj.token}`}
        }

        return options;
    }

    async controlarModalEdicao(idItem) {
        const options = this.getOptions();
        await api.get(`${this.state.url}/${idItem}`, options)
        .then(({ data })=> {

          console.log(data);

          this.controlarModal();

          setTimeout(() => {
              this.setState({
                whatsapp: data.whatsapp
              })

              this.setState({
                instagram: data.instagram
              })

              this.setState({
                telefone: data.ligacao
              })

              this.setState({
                email: data.email
              })
              this.setValueId('idItem', idItem);
              this.setValueId('nome', data.nome);
              this.setValueId('descricao', data.descricao);
              this.setValueId('inicio', moment(data.inicio).format('YYYY-MM-DD'));
              this.setValueId('fim', moment(data.fim).format('YYYY-MM-DD'));
          }, 500);
        });
    }

    async controlarModalListaPresenca(idItem) {
      const options = this.getOptions();
      await api.get(`${this.state.url}/${idItem}`, options)
      .then(({ data })=> {

        this.controlarModalLista();

        setTimeout(() => {
            this.setValueId('idItem', idItem);
            this.setValueId('nome', data.nome);
        }, 500);
      });

      await api.get(`${this.state.url}/listapresenca/${idItem}`, options)
      .then(({ data })=> {
        setTimeout(() => {
            this.setState({
              listapresenca: data
            });
        }, 500);
      });
  }

    cadastrarOuEditar() {
      const existeId = document.getElementById('idItem').value;

      if (existeId) {
        this.editarItem();
      } else {
        this.cadastrarItem();
      }
    }

    async cadastrarItem() {
      try {
        const novoItem = this.getObjeto();
        if (!this.validarCampos(novoItem)) {
          return false;
        }
        const options = this.getOptions();
        await api.post(this.state.url, novoItem, options)
        .then(() => {
          this.controlarModal();
          toast.success('Evento cadastrado.');
          this.buscarDados();
        });

      } catch (error) {
        toast.error('Não foi possível cadastrar o evento.');
      }
    }

    async editarItem() {
      try {
        const itemSelecionado = this.getObjeto();
    
        if (!this.validarCampos(itemSelecionado)) {
          return false;
        }

        const idItem = document.getElementById('idItem').value;
    
        const options = this.getOptions();
        await api.put(`${this.state.url}/${idItem}`, itemSelecionado, options)
        .then(() => {
          this.controlarModal();
          toast.success('Item atualizado');
          this.buscarDados();
        });

      } catch (error) {
        toast.error('Não foi possível editar o item');
      }
    }

    getObjeto() {
      return {
        nome: this.getValueId('nome'),
        descricao: this.getValueId('descricao'),
        inicio: this.getValueId('inicio'),
        fim: this.getValueId('fim')
      };
    }

    validarCampos(item) {
      const { nome, inicio } = item;

      if (!nome) {
        toast.error('Campo nome obrigatório');
        return false;
      }

      if (!inicio) {
        toast.error('Campo data de inicio do evento obrigatório');
        return false;
      }

      return true;
    }

    validarCamposAcao(item) {
      const { tipo, descricao } = item;

      if (!tipo) {
        toast.error('Selecione um tipo de ação');
        return false;
      }

      if (!descricao) {
        toast.error('Informe uma descrição do seu contato');
        return false;
      }

      return true;
    }

    getValueId(nomeId) {
      return document.getElementById(nomeId).value;
    }

    setValueId(nomeId, valor) {
      document.getElementById(nomeId).value = valor;
    }

    controlarModal() {
      this.setState(prevState => ({
        modal_standard: !prevState.modal_standard
      }));
      this.removeBodyCss();
    }

    controlarModalLista() {
      this.setState(prevState => ({
        modal_lista: !prevState.modal_lista
      }));
      this.removeBodyCss();
    }

    removeBodyCss() {
      document.body.classList.add("no_padding");
    }

    componentDidMount() {
      this.buscarDados();
    }

  render() {

    const columns = [{
        dataField: 'nome',
        text: 'Nome',
        sort: true
    }, {
        dataField: 'descricao',
        text: 'Descrição',
        sort: true
    }, {
      dataField: 'inicio',
      text: 'Inicio',
      sort: true,
      formatter: (cellContent, row) => (
        <React.Fragment>
            <>
              {moment(row.inicio).format('DD/MM/YYYY')}
            </>
        </React.Fragment>
      )
    }, {
      dataField: 'fim',
      text: 'Fim',
      sort: true,
      formatter: (cellContent, row) => (
        <React.Fragment>
            <>
              {moment(row.fim).format('DD/MM/YYYY')}
            </>
        </React.Fragment>
      )
    }];

    const defaultSorted = [{
      dataField: 'categorias',
      order: 'asc'
    }];

    const pageOptions = {
      sizePerPage: 10,
      totalSize: this.state.productData.length, 
      custom: true,
    }

    return (
      <React.Fragment>
        <ToastContainer />
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Agenda de Eventos" breadcrumbItems={this.state.breadcrumbItems} />
            <Row className="mb-3">
                {/* <div className="col-md-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex">
                            <div className="flex-1 overflow-hidden">
                                <p className="text-truncate font-size-14 mb-2">Total de eventos</p>
                                <h4 className="mb-0">{this.state.eventosTotal}</h4>
                            </div>
                            <div className="text-primary"><i className="ri-calendar-check-fill font-size-24"></i></div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {this.state.totalData.map((evento) => {
                  return (
                      <div className="col-md-3">
                        <Link to="#">
                          <div className="card">
                              <div className="card-body">
                                  <div className="d-flex">
                                  <div className="flex-1 overflow-hidden">
                                      <p className="text-truncate font-size-14 mb-2" style={{ color: '#ffffff' }}>{evento.nome}</p>
                                  </div>
                                  <div className="text-primary"><i className="ri-calendar-check-fill font-size-24"></i></div>
                                  </div>
                              </div>
                              <div class="border-top py-3 card-body">
                                <div class="text-truncate">
                                  {moment(evento.inicio).diff(moment(new Date()), 'days') < 0 ? 
                                  <>
                                    <span class="badge badge-soft-success font-size-11 me-1">
                                      <i class="mdi mdi-check"></i> Já aconteceu
                                    </span>
                                  </> : 
                                  <>
                                    <span class="badge badge-soft-warning font-size-11 me-1">
                                      <i class="mdi mdi-clock-fast"></i> Faltam {moment(evento.inicio).diff(moment(new Date()), 'days')} dias
                                    </span>
                                  </>}
                                  
                                  <span class="text-muted ms-2">{moment(evento.inicio).format('ll')}</span>
                                </div>
                              </div>
                          </div>
                        </Link>
                      </div>
                  )
                })}
            </Row>
            {/* <div className="row">
                <div className="col-md-2">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex">
                            <div className="flex-1 overflow-hidden">
                                <p className="text-truncate font-size-14 mb-2">Fazer Contato</p>
                                <h4 className="mb-0">{this.state.fazercontato}</h4>
                            </div>
                            <div className="text-primary"><i className="ri-phone-fill font-size-24"></i></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='id'
                      columns={columns}
                      data={this.state.productData}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='id'
                          columns={columns}
                          data={this.state.productData}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>

                              <Row className="mb-2">
                                <Col md="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      {/* <Row className="mb-3">
                                          <Col md={10}>
                                              <Input id="valorPesquisar" type="text" placeholder="Pesquisar" onChange={() => this.pesquisar()}/>
                                          </Col>
                                      </Row> */}
                                      <i className="search-box chat-search-box" />
                                    </div>
                                  </div>
                                </Col>
                                <Col sm="8">
                                  <div className="text-sm-end">
                                    <CustomProvider theme='dark'>
                                      <Stack direction="column" spacing={8} alignItems="flex-start">
                                        <DateRangePicker
                                          ranges={predefinedRanges}
                                          placeholder="Selecionar data"
                                          style={{ width: 300 }}
                                          id="calendario"
                                          onChange={() => setTimeout(() => {
                                            this.buscarDados()
                                          }, 0)}
                                        />
                                      </Stack>
                                    </CustomProvider>
                                    {/* <Button
                                        type="button"
                                        color="primary"
                                        className="btn-rounded mb-2 me-2"
                                        onClick={this.controlarModal}
                                      >
                                        Cadastrar
                                    </Button> */}
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField='idusuarios'
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      ref={(n) => (this.node = n)}
                                      classes={
                                        "table align-middle table-nowrap table-hover"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />

                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
        <Modal
          isOpen={this.state.modal_standard}
          toggle={this.controlarModal}
        >
          <ModalHeader toggle={() => this.setState({ modal_standard: false })}>
              Cadastro de evento
          </ModalHeader>
          <ModalBody>
            <Row className="mb-3">
                <Label className="form-label">Evento</Label>
                <Col md={12}>
                    <Input id="idItem" type="text" style={{ display: 'none' }} />
                    <Input id="nome" type="text" />
                </Col>  
            </Row>
            <Row className="mb-3">
                <Label className="form-label">Descrição</Label>
                <Col md={12}>
                    <Input id="descricao" type="textarea" />
                </Col>  
            </Row>
            <Row className="mb-3">
                <Label className="form-label">Data inicio</Label>
                <Col md={12}>
                    <Input id="inicio" type="date" />
                </Col>  
            </Row>
            <Row className="mb-3">
                <Label className="form-label">Data fim</Label>
                <Col md={12}>
                    <Input id="fim" type="date"  />
                </Col>  
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
                type="button"
                onClick={this.controlarModal}
                color="light"
                className="waves-effect"
              >
                Fechar
            </Button>
            <Button
                type="button"
                color="success" className="waves-effect waves-light"
                onClick={() => this.cadastrarOuEditar()}
              >
                Salvar
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
        size="lg"
          isOpen={this.state.modal_lista}
          toggle={this.controlarModalLista}
        >
          <ModalHeader toggle={() => this.setState({ modal_lista: false })}>
              Lista de presença
          </ModalHeader>
          <ModalBody>
            <Row className="mb-3">
                <Label className="form-label">Evento</Label>
                <Col md={12}>
                    <Input id="idItem" type="text" style={{ display: 'none' }} />
                    <Input id="nome" type="text" disabled/>
                </Col>  
            </Row>
            <Table dark>
              <thead>
                <tr>
                  <th>
                    #
                  </th>
                  <th>
                    Convidado
                  </th>
                  <th>
                    Telefone
                  </th>
                  <th>
                    Executivo
                  </th>
                  {/* <th>
                    Status
                  </th> */}
                </tr>
              </thead>
              <tbody>
                {this.state.listapresenca.map((linha, key) => {
                  return (
                    <>
                    <tr key={key}>
                      <th scope="row">
                        {key+1}
                      </th>
                      <td>
                        {linha.convidado}
                      </td>
                      <td>
                        {linha.whatsapp}
                      </td>
                      <td>
                        {linha.executivo}
                      </td>
                      {/* <td>
                        {linha.status}
                      </td> */}
                    </tr>
                    </>
                  );
                })}
                
              </tbody>
            </Table>
          </ModalBody>
          <ModalFooter>
            <Button
                type="button"
                onClick={this.controlarModalLista}
                color="light"
                className="waves-effect"
              >
                Fechar
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    )
  }
}

export default Agenda;