import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import { Container, Row, Col, Card, CardBody, Input, Label, Button, Table, Modal, ModalHeader, ModalBody, CardHeader, CardTitle, CardText, UncontrolledAlert } from "reactstrap";
import Form from 'react-bootstrap/Form';
// import imgHP from "../../../assets/images/hp.png";
import imgLoading from "../../../assets/images/loading.gif";
import api from '../../../services/api';
import { ToastContainer, toast } from 'react-toastify';

class Indicacoes extends Component {
    constructor(props) {
        super(props);
        this.state={
            nomeCompleto: '',
            username: '',
            activeTab: 1,
            activeTabProgress: 1,
            progressValue: 50,
            modal_venda: false,
            convidadosArray: [],
            idMentorado: 0,
            nomeMentorado:'',
            idEvento: 0,
            eventoCodigo: '',
            eventoNome: '',
            eventoConvidados: 0,
            loading: true,
        };

        this.controlarModalVenda = this.controlarModalVenda.bind(this);
    }

    controlarModalVenda() {
        this.setState(prevState => ({
          modal_venda: !prevState.modal_venda
        }));
        this.removeBodyCss();
    }

    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    getObjetoM7D() {
        if(!document.getElementById('nomeM7d').value) {
            toast.error('Informe o seu nome.');
            throw new Error("Erro");
        }

        if(!document.getElementById('whatsappM7d').value) {
            toast.error('Informe o seu whatsapp.');
            throw new Error("Erro");
        }

        return {
            nome: document.getElementById('nomeM7d').value,
            whatsapp: document.getElementById('whatsappM7d').value,
        }
    }

    getObjetoConvidado() {
        if(!document.getElementById('nome').value) {
            toast.error('Informe o nome do convidado.');
            throw new Error("Erro");
        }

        if(!document.getElementById('whatsapp').value) {
            toast.error('Informe o whatsapp do convidado.');
            throw new Error("Erro");
        }

        if(!document.getElementById('area').value) {
            toast.error('Informe a área de atuação do convidado.');
            throw new Error("Erro");
        }

        if(!document.getElementById('documento').value) {
            toast.error('Informe o RG do seu convidado.');
            throw new Error("Erro");
        }

        return {
            nome: document.getElementById('nome').value,
            whatsapp: document.getElementById('whatsapp').value,
            area: document.getElementById('area').value,
            documento: document.getElementById('documento').value
        }
    }

    async login() {
        try {
            const mentorado = this.getObjetoM7D();
            const codigo = this.getCodigo();

            await api.post(`/mentorados/indicacao?idEvento=${codigo}`, mentorado).then(({ data }) => {
                this.setState({
                    idMentorado: data.codigo,
                    nomeMentorado: data.nome
                })
                console.log(data);
                toast.success('Bem vindo M7D. Cadastre os seus convidados.');
                this.getConvidados();
                this.getConviteMentorado();
            });
        } catch (error) {
            
        }
    }

    getCodigo() {
        const url = new URLSearchParams(window.location.search);
        return url.get('q');
    }

    async getEvento() {
        try {
            const codigo = this.getCodigo();

            await api.get(`/eventos/codigo/${codigo}`).then(({ data }) => {
                this.setState({
                    eventoCodigo: data.codigo,
                    eventoNome: data.nome,
                    eventoConvidados: data.convidados,
                    loading: false
                });

                toast.success('Faça a sua identificação');
            });
        } catch (error) {
            this.setState({
                loading: false
            });
        }
    }

    async cancelarConvidado(idConvidado) {
        try {
            await api.put(`/eventos/convidado/${idConvidado}?idEvento=${this.state.eventoCodigo}&idMentorado=${this.state.idMentorado}`)
            .then(() => {
                toast.error('Convidado cancelado.');
                this.getConvidados();
            });
        } catch (error) {
            
        }
    }

    async cadastrarConvidado() {
        try {
            const convidado = this.getObjetoConvidado();

            await api.post(`/mentorados/convidado?idEvento=${this.state.eventoCodigo}&idMentorado=${this.state.idMentorado}`, convidado)
            .then(({ data }) => {
                toast.success('Convidado cadastrado com sucesso.');
                this.controlarModalVenda();
                this.getConvidados();
            });
        } catch (error) {
            
        }
    }

    async conviteMentorado() {
        try {
            const convidado = {
                status: !document.getElementById('square-switch3').checked
            }

            await api.post(`/eventos/mentorado?idEvento=${this.state.eventoCodigo}&idMentorado=${this.state.idMentorado}`, convidado)
            .then(() => {
                toast.success('Presença confimada');
            });
        } catch (error) {
            
        }
    }

    async getConviteMentorado() {
        try {
            await api.get(`/mentorados/confirma/presenca?idEvento=${this.state.eventoCodigo}&idMentorado=${this.state.idMentorado}`)
            .then(({data}) => {
                document.getElementById('square-switch3').checked = data.status;
            });
        } catch (error) {
            
        }
    }

    async getConvidados() {
        try {
            await api.get(`/eventos/convidados?idEvento=${this.state.eventoCodigo}&idMentorado=${this.state.idMentorado}`).then(({ data }) => {
                this.setState({
                    convidadosArray: data
                });
            });
        } catch (error) {
            this.setState({
                loading: false
            });
        }
    }

    componentDidMount() {
        this.getEvento();
    }

    render() {
        return (
            <React.Fragment>
                <ToastContainer />
                <div className="my-5 pt-sm-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col xs={12}>
                                <div className="">

                                    

                                    { this.state.eventoCodigo ? <>
                                        <Row className="justify-content-md-center">
                                        <Col lg="6">
                                            <Card>
                                                <CardBody>
                                                    <Row className="justify-content-md-center">
                                                        <Col xs={12} sm={4} md={4}>
                                                            <div className="">
                                                                {/* <img className='rounded mx-auto d-block' src={imgHP} alt="Método HP" height="100px" /> */}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <br />

                                                    <div className="twitter-bs-wizard">
                                                        <Form>
                                                            {this.state.idMentorado ? 
                                                            <>
                                                                <h3 className="mb-4 text-center">{this.state.eventoNome}</h3>
                                                                <br />
                                                                <div className="row mb-4">
                                                                    <div className="col-md-12">
                                                                        <span className="font-size-20">
                                                                            {this.state.nomeMentorado} você vem no evento? &nbsp;
                                                                        </span>
                                                                        <input
                                                                            type="checkbox"
                                                                            id="square-switch3"
                                                                            switch="bool"
                                                                            checked={this.state.sq3}
                                                                        />
                                                                        <label
                                                                            htmlFor="square-switch3"
                                                                            data-on-label="Sim"
                                                                            data-off-label="Não"
                                                                            onClick={() => this.conviteMentorado()}
                                                                            style={{marginBottom: '-5px'}}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <h3 className="mb-4">Lista de convidados</h3>
                                                                <div className="table-responsive">
                                                                    { this.state.convidadosArray.length > 0 ? <>
                                                                        <Table hover className="mb-0 table-centered table-nowrap">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>Convidado</th>
                                                                                    <th className='text-center'>Opcões</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {this.state.convidadosArray.map((convidado) => {
                                                                                    return (
                                                                                        <tr>
                                                                                            <td>{convidado.nome}</td>
                                                                                            <td>
                                                                                                <div className="d-grid">
                                                                                                    <Button
                                                                                                        color="danger"
                                                                                                        outline
                                                                                                        className="waves-effect waves-light me-1 btn-rounded"
                                                                                                        onClick={() => this.cancelarConvidado(convidado.leads_id)}
                                                                                                    >
                                                                                                        Cancelar convite
                                                                                                    </Button>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    )
                                                                                })}
                                                                                
                                                                            </tbody>
                                                                        </Table>
                                                                    </> : <>
                                                                    </>}
                                                                    
                                                                </div>
                                                                <hr />
                                                                <div>
                                                                    <div className="d-grid mb-3">
                                                                        {this.state.eventoConvidados > this.state.convidadosArray.length ? <>
                                                                            <Button
                                                                                color="primary"
                                                                                className="btn btn-primary btn-lg btn-block"
                                                                                onClick={this.controlarModalVenda}
                                                                            >
                                                                                Cadastrar convidado
                                                                            </Button>
                                                                        </> : <>
                                                                            <UncontrolledAlert color="success" className="alert-dismissible fade show" role="alert">
                                                                                <i className="mdi mdi-check-all me-2"></i>
                                                                                <b>Consumo total de {this.state.eventoConvidados}</b> convites. Parabéns por essa conquista!!!
                                                                            </UncontrolledAlert>
                                                                        </>}
                                                                        
                                                                    </div>
                                                                </div>
                                                            </> : <>
                                                                <Row>
                                                                    <Col lg="12">
                                                                        <div className="mb-3">
                                                                            <h2 className="form-label">{this.state.eventoNome}</h2>
                                                                        </div>
                                                                    </Col>
                                                                </Row>

                                                                <Row>
                                                                    <Col lg="12">
                                                                        <div className="mb-3">
                                                                            <Label className="form-label">Nome completo do M7D</Label>
                                                                            <Input type="text" className="form-control" id="nomeM7d"/>
                                                                        </div>
                                                                    </Col>
                                                                </Row>

                                                                <Row>
                                                                    <Col lg="12">
                                                                        <div className="mb-3">
                                                                            <Label className="form-label">WhatsApp do M7D</Label>
                                                                            <Form.Control
                                                                                as={InputMask}
                                                                                className="form-control"
                                                                                mask="(99) 9 9999-9999"
                                                                                placeholder=""
                                                                                id="whatsappM7d"
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <hr />
                                                                <div>
                                                                    <div className="d-grid mb-3">
                                                                        <Button
                                                                            color="primary"
                                                                            type="button"
                                                                            className="btn btn-info btn-lg btn-block"
                                                                            onClick={() => this.login()}
                                                                        >
                                                                            INICIAR
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </>}
                                                        </Form>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        </Row>
                                    </> : <>
                                        <Row className="justify-content-md-center">
                                            <Col xs={12} sm={12} md={12}>
                                                {this.state.loading ? <>
                                                    <div className="">
                                                        <img className='justify-content-center rounded mx-auto d-block' src={imgLoading} alt="Método HP" height="200px" />
                                                    </div>
                                                </> : <>
                                                    <Col lg={4}>
                                                        <Card outline color="sucess" className="border">
                                                            <CardHeader className="bg-transparent">
                                                                <h5 className="my-0 text-success"><i className="mdi mdi-calendar-month-outline me-3"></i>Gestão de Eventos</h5>
                                                            </CardHeader>
                                                            <CardBody>
                                                                <CardTitle className="h4">Recado importante</CardTitle>
                                                                <CardText>O evento que procura não está disponivel para envio de convites. Avise o seu gerente de sucesso.</CardText>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                </>}
                                            </Col>
                                        </Row>
                                    </>}
                                    
                                    
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <Modal
                    isOpen={this.state.modal_venda}
                    toggle={this.controlarModalVenda}
                    className="modal-dialog-centered"
                >
                <ModalHeader toggle={() => this.setState({ modal_venda: false })}>
                    Cadastro do convidado
                </ModalHeader>
                <ModalBody>

                    <Row className="mb-3">
                        <Label className="form-label">Nome</Label>
                        <Col md={12}>
                            <Input id="idConvidado" type="text" style={{ display: 'none' }}/>
                            <Input id="nome" type="text" />
                        </Col>  
                    </Row>

                    <Row className="mb-3"> 
                        <Label className="form-label">WhatsApp</Label>
                        <Col md={12}>
                            <Form.Control
                                as={InputMask}
                                className="form-control"
                                mask="(99) 9 9999-9999"
                                id="whatsapp"
                            />
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Label className="form-label">RG</Label>
                        <Col md={12}>
                            <Input id="documento" type="text"/>
                        </Col>  
                    </Row>

                    <Row className="mb-3">
                        <Label className="form-label">Área de atuação</Label>
                        <Col md={12}>
                            <Input id="area" type="text"/>
                        </Col>  
                    </Row>

                </ModalBody>
                    <Col lg="12" style={{padding: '0 20px'}}>
                        <div className="d-grid mb-3">
                            <Button
                                color="success"
                                className="btn btn-primary btn-lg btn-block"
                                onClick={() => this.cadastrarConvidado()}
                            >
                                Salvar convidado
                            </Button>
                        </div>
                    </Col>
                
                </Modal>
            </React.Fragment>
        );
    }
}

export default Indicacoes;