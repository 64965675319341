import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import RedefinirSenha from "../pages/Authentication/RedefinirSenha";
import EsqueciMinhaSenha from "../pages/Authentication/EsqueciMinhaSenha";
import AuthLockScreen from "../pages/Authentication/AuthLockScreen";

// Dashboard
import Dashboard from "../pages/Dashboard/index";

import Perfil from "../pages/Perfil/index";

import CadastroMembros from "../pages/Authentication/CadastroMembros";

//Icons
import RemixIcons from "../pages/Icons/RemixIcons";
import MaterialDesign from "../pages/Icons/MaterialDesign";
import DripiIcons from "../pages/Icons/DripiIcons";
import FontAwesome from "../pages/Icons/FontAwesome";

//Utility
import StarterPage from "../pages/Utility/StarterPage";
import Maintenance from "../pages/Utility/Maintenance";
import CommingSoon from "../pages/Utility/CommingSoon";
import Timeline from "../pages/Utility/Timeline";
import FAQs from "../pages/Utility/FAQs";
import Pricing from "../pages/Utility/Pricing";
import Error404 from "../pages/Utility/Error404";
import Error500 from "../pages/Utility/Error500";

// Forms
import FormElements from "../pages/Forms/FormElements";
import FormAdvanced from "../pages/Forms/FormAdvanced";
import FormEditors from "../pages/Forms/FormEditors";
import FormValidations from "../pages/Forms/FormValidations";
import FormMask from "../pages/Forms/FormMask";
import FormUpload from "../pages/Forms/FormUpload";
import FormWizard from "../pages/Forms/FormWizard";
import FormXeditable from "../pages/Forms/FormXeditable";

//Ui
import UiAlert from "../pages/Ui/UiAlert";
import UiButtons from "../pages/Ui/UiButtons";
import UiCards from "../pages/Ui/UiCards";
import UiCarousel from "../pages/Ui/UiCarousel";
import UiDropdown from "../pages/Ui/UiDropdown";
import UiGeneral from "../pages/Ui/UiGeneral";
import UiGrid from "../pages/Ui/UiGrid";
import UiImages from "../pages/Ui/UiImages";
import UiLightbox from "../pages/Ui/UiLightbox";
import UiModal from "../pages/Ui/UiModal";
import UiProgressbar from "../pages/Ui/UiProgressbar";
import UiSweetAlert from "../pages/Ui/UiSweetAlert";
import UiTabsAccordions from "../pages/Ui/UiTabsAccordions";
import UiTypography from "../pages/Ui/UiTypography";
import UiVideo from "../pages/Ui/UiVideo";
import UiSessionTimeout from "../pages/Ui/UiSessionTimeout";
import UiRating from "../pages/Ui/UiRating";
import UiRangeSlider from "../pages/Ui/UiRangeSlider";
import UiNotifications from "../pages/Ui/ui-notifications";
import UIRoundSlider from "../pages/Ui/UIRoundSlider";

//Tables
import BasicTables from "../pages/Tables/BasicTables";
import DatatableTables from "../pages/Tables/DatatableTables";
import ResponsiveTables from "../pages/Tables/ResponsiveTables";
import EditableTables from "../pages/Tables/EditableTables";

// Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login";
import Register1 from "../pages/AuthenticationInner/Register";
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword";

// Executivo

import Academia from "../pages/Executivo/Academia";
import Acoes from "../pages/Executivo/Acoes";
import Agenda from "../pages/Executivo/Agenda";
import Areas from "../pages/Executivo/Areas";
import Rumo from "../pages/Executivo/Rumo";
import Recursos from "../pages/Executivo/Recursos";
import Conhecimento from "../pages/Executivo/Conhecimento";
import Treinamento from "../pages/Executivo/Treinamento";
import Modelagem from "../pages/Executivo/Modelagem";
import Network from "../pages/Executivo/Network";
import Horas from "../pages/Executivo/Horas";
import Calendario from "../pages/Executivo/Calendario";
import Clientes from "../pages/Executivo/Clientes";
import Checkin from "../pages/Executivo/Checkin";
import Equipe from "../pages/Executivo/Equipe";
import Formas from "../pages/Executivo/Formas";
import Habitos from "../pages/Executivo/Habitos";
import Indicacoes from "../pages/Executivo/Indicacoes";
import Leads from "../pages/Executivo/Leads";
import Metas from "../pages/Executivo/Metas";
import Premiacao from "../pages/Executivo/Premiacao";
import Produtos from "../pages/Executivo/Produtos";
import Ranking from "../pages/Executivo/Ranking";
import Vendas from "../pages/Executivo/Vendas";

const authProtectedRoutes = [

	// Executivo
	{ path: "/academia", component: Academia },
	{ path: "/acoes", component: Acoes },
	{ path: "/agenda", component: Agenda },
	{ path: "/areas", component: Areas },
	{ path: "/rumo", component: Rumo },
	{ path: "/recursos", component: Recursos },
	{ path: "/conhecimento", component: Conhecimento },
	{ path: "/modelagem", component: Modelagem },
	{ path: "/network", component: Network },
	{ path: "/horas", component: Horas },
	{ path: "/treinamento", component: Treinamento },
	{ path: "/calendario", component: Calendario },
	{ path: "/clientes", component: Clientes },
	{ path: "/equipe", component: Equipe },
	{ path: "/formas", component: Formas },
	{ path: "/habitos", component: Habitos },
	{ path: "/leads", component: Leads },
	{ path: "/metas", component: Metas },
	{ path: "/premiacao", component: Premiacao },
	{ path: "/produtos", component: Produtos },
	{ path: "/ranking", component: Ranking },
	{ path: "/vendas", component: Vendas },

	// Tables
	{ path: "/basic-tables", component: BasicTables },
	{ path: "/datatable-table", component: DatatableTables },
	{ path: "/responsive-table", component: ResponsiveTables },
	{ path: "/editable-table", component: EditableTables },

	// Ui
	{ path: "/ui-alerts", component: UiAlert },
	{ path: "/ui-buttons", component: UiButtons },
	{ path: "/ui-cards", component: UiCards },
	{ path: "/ui-carousel", component: UiCarousel },
	{ path: "/ui-dropdowns", component: UiDropdown },
	{ path: "/ui-general", component: UiGeneral },
	{ path: "/ui-grid", component: UiGrid },
	{ path: "/ui-images", component: UiImages },
	{ path: "/ui-lightbox", component: UiLightbox },
	{ path: "/ui-modals", component: UiModal },
	{ path: "/ui-progressbars", component: UiProgressbar },
	{ path: "/ui-sweet-alert", component: UiSweetAlert },
	{ path: "/ui-tabs-accordions", component: UiTabsAccordions },
	{ path: "/ui-typography", component: UiTypography },
	{ path: "/ui-video", component: UiVideo },
	{ path: "/ui-session-timeout", component: UiSessionTimeout },
	{ path: "/ui-rating", component: UiRating },
	{ path: "/ui-rangeslider", component: UiRangeSlider },
	{ path: "/ui-notifications", component: UiNotifications },
	{ path: "/ui-roundslider", component: UIRoundSlider },

	// Forms
	{ path: "/form-elements", component: FormElements },
	{ path: "/form-advanced", component: FormAdvanced },
	{ path: "/form-editors", component: FormEditors },
	{ path: "/form-mask", component: FormMask },
	{ path: "/form-file-upload", component: FormUpload },
	{ path: "/form-wizard", component: FormWizard },
	{ path: "/form-validation", component: FormValidations },
	{ path: "/form-xeditable", component: FormXeditable },

	//Utility
	{ path: "/starter", component: StarterPage },
	{ path: "/planoleitura", component: Timeline },
	{ path: "/faqs", component: FAQs },
	{ path: "/pricing", component: Pricing },

	//Icons
	{ path: "/icons-remix", component: RemixIcons },
	{ path: "/material-design", component: MaterialDesign },
	{ path: "/dripicons", component: DripiIcons },
	{ path: "/font-awesome-5", component: FontAwesome },

	{ path: "/dashboard", component: Dashboard },

	{ path: "/perfil", component: Perfil },

	{ path: "/", exact: true, component: () => <Redirect to="/login" /> }
];

const publicRoutes = [
	{ path: "/logout", component: Logout },
	{ path: "/login", component: Login },
	{ path: "/esqueci-minha-senha", component: EsqueciMinhaSenha },
	{ path: "/redefinir-senha", component: RedefinirSenha },
	{ path: "/cadastro", component: Register },
	{ path: "/membros", component: CadastroMembros },
	{ path: "/lock-screen", component: AuthLockScreen },
	{ path: "/indicacoes", component: Indicacoes },
	{ path: "/checkin", component: Checkin },
	

	// Authentication Inner
	{ path: "/auth-login", component: Login1 },
	{ path: "/auth-register", component: Register1 },
	{ path: "/auth-recoverpw", component: ForgetPwd1 },

	{ path: "/maintenance", component: Maintenance },
	{ path: "/comingsoon", component: CommingSoon },
	{ path: "/404", component: Error404 },
	{ path: "/500", component: Error500 },
];

export { authProtectedRoutes, publicRoutes };
