import React, { Component } from "react";
import moment from 'moment';

import { DateRangePicker, Stack, CustomProvider } from 'rsuite';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';

import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import { ToastContainer, toast } from 'react-toastify';

import ToolkitProvider from 'react-bootstrap-table2-toolkit';

import api from '../../../services/api';

import Breadcrumbs from "../../../components/Common/Breadcrumb";
import PredefinedRanges from '../../../components/PredefinedRanges';
import "../../../assets/scss/datatables.scss";

class Vendas extends Component {
    constructor(props) {
      super(props)
      this.state = {
        breadcrumbItems: [
          { title: "Menu", link: "/dashboard" },
          { title: "Vendas", link: "#" },
        ],
        page: 1,
        sizePerPage: 10,
        productData: [],
        acaoData: [],
        eventoData: [],
        eventoLeadData: [],
        produtosData: [],
        visible: false,
        filtro: '',
        url: '/executivo/vendas',
        fazercontato: 0,
        emcontato: 0,
        agendadozoom: 0,
        zoomok: 0,
        linkenviado: 0,
        emnegociacao: 0,
        propostarecusada: 0,
        vendaextraordinaria: 0,
        vendaemaberto: 0,
        semretorno: 0,
        vendasExecutivo: '0,00',
        comissao: '0,00',
        ranking: '',
        link: process.env.NODE_ENV === 'development'? 'http://localhost:3003' : 'https://api.venda.show',
        comprovante_atual: '',
        comprovante_entrada_atual: '',
        mesAtual: [startOfMonth(new Date()), endOfMonth(new Date())],
      }
    };

    async buscarDados() {
      const options = this.getOptions();

      let filtro = [];
  
      const range = this.state.mesAtual;
      if (range) {
        filtro = range;
      }

      const inicio = this.formatar(filtro[0]);
      const fim = this.formatar(filtro[1]);

      const { data } = await api.get(`${this.state.url}?inicio=${inicio}&fim=${fim}`, options);

      this.setState({
        productData: data
      });
    }

    setFiltroCategoria(categoria) {
      this.setValueId('filtroCategoria', categoria);

      this.buscarDados();
    }

    async buscarDadosAcao(idLead) {
      const options = this.getOptions();

      const { data } = await api.get(`${this.state.url}/acao/${idLead}`, options);

      this.setState({
        acaoData: data
      });
    }

    async buscarProdutos() {
      const options = this.getOptions();

      const { data } = await api.get(`/produtos/executivo`, options);

      this.setState({
        produtosData: data
      });
    }

    async buscarVendasExecutivo() {
      const options = this.getOptions();

      let filtro = [];
  
      const range = this.state.mesAtual;
      if (range) {
        filtro = range;
      }

      const inicio = this.formatar(filtro[0]);
      const fim = this.formatar(filtro[1]);

      const { data } = await api.get(`/executivo/comissao?inicio=${inicio}&fim=${fim}`, options);

      const valorFormatado = data.valor_total;

      this.setState({
        vendasExecutivo: valorFormatado? parseFloat(valorFormatado).toLocaleString('pt-br', {minimumFractionDigits: 2}): '0,00',
        comissao: valorFormatado? parseFloat(valorFormatado).toLocaleString('pt-br', {minimumFractionDigits: 2}): '0,00',
      });
    }

    getOptions() {
        let obj = '';
        if (localStorage.getItem("authUser")) {
        obj = JSON.parse(localStorage.getItem("authUser"));
        }

        const options = {
        headers: {"Authorization" : `Bearer ${obj.token}`}
        }

        return options;
    }

    getOptionsVenda() {
      let obj = '';
      if (localStorage.getItem("authUser")) {
      obj = JSON.parse(localStorage.getItem("authUser"));
      }

      const options = {
        headers: {
          "Authorization" : `Bearer ${obj.token}`,
          "Content-Type": "multipart/form-data"
        }
      }

      return options;
  }

    getObjeto() {
      return {
        nome: this.getValueId('nome'),
        whatsapp: this.getValueId('whatsapp'),
        instagram: this.getValueId('instagram'),
        email: this.getValueId('email'),
        origem: this.getValueId('origem'),
        status: this.getValueId('status1'),
        hp: this.getValueId('hp'),
        nivel: this.getValueId('nivel')
      };
    }

    getObjetoEvento() {
      return {
        leads_id: this.getValueId('idLead'),
        status: 'aguardando_evento',
        eventos_id: this.getValueId('eventos_id'),
      };
    }

    validarCampos(item) {
      const { nome } = item;

      if (!nome) {
        toast.error('Campo nome obrigatório');
        return false;
      }

      return true;
    }

    getValueId(nomeId) {
      return document.getElementById(nomeId).value;
    }

    setValueId(nomeId, valor) {
      document.getElementById(nomeId).value = valor;
    }

    formatar(data) {
      return moment(data).format('YYYY/MM/DD');
    }

    componentDidMount() {
      this.buscarDados();
      this.buscarVendasExecutivo();
    }

    getStatusFinanceiro(status) {
      if (status === 'analisar') {
        return 'info';
      }

      if (status === 'finalizada') {
        return 'success';
      }

      if (status === 'cancelada') {
        return 'danger';
      }

      if (status === 'reembolso') {
        return 'dark';
      }

      return 'warning';
    }

  render() {

    const columns = [
      {
        dataField: 'id',
        text: '#',
        sort: true
    },{
        dataField: 'data_venda',
        text: 'Data da Venda',
        sort: true,
        formatter: (cellContent, row) => (
            <React.Fragment>
                {moment(row.data_venda).format('DD/MM/YYYY')}
            </React.Fragment>
        )
    }, {
        dataField: 'executivo',
        text: 'Executivo',
        sort: true
    }, {
        dataField: 'comissao',
        text: 'Comissão',
        sort: true,
        formatter: (cellContent, row) => (
          <React.Fragment>
              R$ {parseFloat(row.comissao).toLocaleString('pt-br', {minimumFractionDigits: 2})}
          </React.Fragment>
        )
    }, {
        dataField: 'cliente',
        text: 'Cliente',
        sort: true
    }, {
        dataField: 'valor_produto',
        text: 'Valor produto',
        sort: true,
        formatter: (cellContent, row) => (
            <React.Fragment>
                {row.comprovante ? <>
                    <Link 
                        to={{pathname: `${this.state.link}/anexos/${row.comprovante}`}} 
                        className="btn-sm"
                        target="_blank"
                        title="Comprovante de pagamento da venda"
                        style={{ color: '#d5d5d5' }}
                        >
                    <i className="mdi mdi-attachment font-size-18 text-info"></i> &nbsp;
                    R$ {parseFloat(row.valor_produto).toLocaleString('pt-br', {minimumFractionDigits: 2})} 
                    </Link>
                </> : <>
                  R$ {parseFloat(row.valor_produto).toLocaleString('pt-br', {minimumFractionDigits: 2})}
                </>}
            </React.Fragment>
        )
    }, {
        dataField: 'forma_pagamento',
        text: 'Forma Pagamento',
        sort: true
    }, {
        dataField: 'valor_entrada',
        text: 'Valor entrada',
        sort: true,
        formatter: (cellContent, row) => (
            <React.Fragment>
                {row.comprovante_entrada ? <>
                    <Link 
                        to={{pathname: `${this.state.link}/anexos/${row.comprovante_entrada}`}} 
                        className="btn-sm"
                        target="_blank"
                        title="Comprovante de pagamento da venda"
                        style={{ color: '#d5d5d5' }}
                        >
                    <i className="mdi mdi-attachment font-size-18 text-info"></i> &nbsp;
                    R$ {parseFloat(row.valor_entrada).toLocaleString('pt-br', {minimumFractionDigits: 2})} 
                    </Link>
                </> : <>
                  R$ {parseFloat(row.valor_entrada).toLocaleString('pt-br', {minimumFractionDigits: 2})} &nbsp;
                </>}
            </React.Fragment>
        )
    }, {
        dataField: 'forma_pagamento_entrada',
        text: 'Forma Pag. Entrada',
        sort: true
    }, {
        dataField: 'status',
        text: 'Status',
        sort: true
    }, {
        dataField: 'status_financeiro',
        text: 'Financeiro',
        sort: true,
        formatter: (cellContent, row) => (
            <React.Fragment>
                <span class={`badge-soft-${this.getStatusFinanceiro(row.status_financeiro)} rounded-pill me-1 badge badge-secondary font-size-16`}>
                  {row.status_financeiro}
                </span>
            </React.Fragment>
        )
    }];

    const defaultSorted = [{
      dataField: 'categorias',
      order: 'asc'
    }];

    const pageOptions = {
      sizePerPage: 10,
      totalSize: this.state.productData.length, // replace later with size(customers),
      custom: true,
    }

    return (
      <React.Fragment>
        <ToastContainer />
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Relatório de vendas" breadcrumbItems={this.state.breadcrumbItems} />
            <div className="row">
                <div className="col-md-4">
                    <div className="card" title="A comissão aparece depois que a venda for aprovada pelo financeiro.">
                        <div className="card-body">
                            <div className="d-flex">
                            <div className="flex-1 overflow-hidden">
                                <h5>COMISSÃO</h5>
                                <h5 className="mb-0">R$ {this.state.comissao}</h5>
                            </div>
                            <div className="text-warning"><i className="ri-medal-line font-size-24"></i></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='id'
                      columns={columns}
                      data={this.state.productData}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='id'
                          columns={columns}
                          data={this.state.productData}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>

                              <Row className="mb-2">
                                <Col md="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <Row className="mb-3">
                                          <Col md={10}>
                                          </Col>
                                      </Row>
                                      <i className="search-box chat-search-box" />
                                    </div>
                                  </div>
                                </Col>
                                <Col sm="8">
                                  <div className="text-sm-end">
                                    <CustomProvider theme='dark'>
                                      <Stack direction="column" spacing={8} alignItems="flex-start">
                                        <DateRangePicker
                                          ranges={PredefinedRanges}
                                          placeholder="Selecionar data"
                                          style={{ width: 300 }}
                                          id="calendario"
                                          value={this.state.mesAtual}
                                          onChange={ 
                                            event => {
                                              setTimeout(() => {
                                                this.setState({ mesAtual : event });
                                                this.buscarDados();
                                                this.buscarVendasExecutivo();
                                              }, 0);
                                            }
                                          }
                                        />
                                      </Stack>
                                    </CustomProvider>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField='idusuarios'
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      ref={(n) => (this.node = n)}
                                      classes={
                                        "table align-middle table-nowrap table-hover"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />

                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default Vendas;