import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import { Row, Col, Card, CardBody, Input, Label, Button, Table, Modal, ModalHeader, ModalBody, CardHeader, CardTitle, CardText, Badge } from "reactstrap";
import Form from 'react-bootstrap/Form';
import { Link } from "react-router-dom";
// import imgHP from "../../../assets/images/hp.png";
import imgLoading from "../../../assets/images/loading.gif";
import api from '../../../services/api';
import { ToastContainer, toast } from 'react-toastify';

class Checkin extends Component {
    constructor(props) {
        super(props);
        this.state={
            nomeCompleto: '',
            username: '',
            activeTab: 1,
            activeTabProgress: 1,
            progressValue: 50,
            modal_venda: false,
            convidadosArray: [],
            idMentorado: 0,
            idEvento: 0,
            eventoCodigo: '',
            eventoNome: '',
            eventoConvidados: 0,
            loading: true,
        };

        this.controlarModalVenda = this.controlarModalVenda.bind(this);
    }

    controlarModalVenda() {
        this.setState(prevState => ({
          modal_venda: !prevState.modal_venda
        }));
        this.removeBodyCss();
    }

    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    getObjetoCodigo() {
        if(!this.state.eventoCodigo) {
            toast.error('Informe o código do evento');
            throw new Error("Erro");
        }

        return {
            codigoCheckin: this.state.eventoCodigo
        }
    }

    getObjetoConvidado() {
        if(!document.getElementById('nome').value) {
            toast.error('Informe o nome do convidado.');
            throw new Error("Erro");
        }

        if(!document.getElementById('whatsapp').value) {
            toast.error('Informe o whatsapp do convidado.');
            throw new Error("Erro");
        }

        if(!document.getElementById('area').value) {
            toast.error('Informe a área de atuação do convidado.');
            throw new Error("Erro");
        }

        if(!document.getElementById('documento').value) {
            toast.error('Informe o RG do seu convidado.');
            throw new Error("Erro");
        }

        return {
            nome: document.getElementById('nome').value,
            whatsapp: document.getElementById('whatsapp').value,
            area: document.getElementById('area').value,
            documento: document.getElementById('documento').value
        }
    }

    async login() {
        try {
            const codigoEvento = document.getElementById('codigo-checkin').value;

            await api.get(`/eventos/codigo/${codigoEvento}`).then(({ data }) => {
                this.setState({
                    eventoCodigo: data.codigo,
                    eventoNome: data.nome,
                    eventoConvidados: data.convidados,
                    loading: false
                });
                toast.success('Faça o checkin do evento.');
                this.getConvidados();
            });
        } catch (error) {
            toast.error('Código do evento inválido.');
        }
    }

    async getEvento() {
        try {
            const codigo = document.getElementById('codigo-checkin').value;

            await api.get(`/eventos/codigo/${codigo}`).then(({ data }) => {
                this.setState({
                    eventoCodigo: data.codigo,
                    eventoNome: data.nome,
                    eventoConvidados: data.convidados,
                    loading: false
                });

                toast.success('Faça a sua identificação');
            });
        } catch (error) {
            this.setState({
                loading: false
            });
        }
    }

    async checkinConvidado(idConvidado, status, mentorados_id) {
        try {
            await api.put(`/eventos/convidado/checkin/${idConvidado}?idEvento=${this.state.eventoCodigo}&idMentorado=${mentorados_id ? mentorados_id : this.state.idMentorado}&statusCheckin=${status}`)
            .then(() => {
                toast.info('Checkin feito com sucesso.');
                this.getConvidados();
            });
        } catch (error) {
            
        }
    }

    async cadastrarConvidado() {
        try {
            const convidado = this.getObjetoConvidado();

            await api.post(`/mentorados/convidado?idEvento=${this.state.eventoCodigo}&idMentorado=${this.state.idMentorado}`, convidado)
            .then(({ data }) => {
                toast.success('Convidado cadastrado com sucesso.');
                this.controlarModalVenda();
                this.getConvidados();
            });
        } catch (error) {
            
        }
    }

    async getConvidados() {
        try {
            await api.get(`/eventos/listapresenca/codigo/${this.state.eventoCodigo}`).then(({ data }) => {
                this.setState({
                    convidadosArray: data
                });
            });
        } catch (error) {
            this.setState({
                loading: false
            });
        }
    }

    getParticipante(participante) {
        console.log(participante);
        if (participante.leads_id === null && participante.executivo === null) {
            return (
                <>
                    {participante.mentorado}
                </>
            );
        }

        if (participante.executivo && participante.leads_id > 0) {
            return (
                <>
                    {participante.convidado}
                </>
            );
        }

        if (participante.convidado && participante.mentorado) {
            return (
                <>
                    {participante.convidado}
                </>
            );
        }
    }

    getTipo(participante) {
        console.log(participante);
        if (participante.leads_id === null && participante.executivo === null) {
            return (
                <>
                    <Badge className="bg-primary me-1 rounded-pill font-size-12"> Mentorado</Badge>
                </>
            );
        }

        if (participante.executivo && participante.leads_id > 0) {
            return (
                <>
                    <Badge className="bg-primary me-1 rounded-pill font-size-12"> Venda</Badge>
                </>
            );
        }

        if (participante.convidado && participante.mentorado) {
            return (
                <>
                    <Badge className="bg-primary me-1 rounded-pill font-size-12"> Convidado</Badge>
                </>
            );
        }
    }

    truncate(str, n){
        if (!str) return '';

        return <>{(str.length > n) ? <>{`${str.slice(0, n-1)}`} &hellip;</> : str}</>
    };

    componentDidMount() {
        //this.getEvento();
    }

    render() {
        return (
            <React.Fragment>
                <ToastContainer />
                <div className="my-5 pt-sm-5">
                    <Row className="justify-content-center">
                        <Col xs={12}>
                            <div className="">

                                

                                { true ? <>
                                    <Row className="justify-content-md-center">
                                    <Col lg="12">
                                        <Card>
                                            <CardBody>
                                                <Row className="justify-content-md-center">
                                                    <Col xs={12} sm={4} md={4}>
                                                        <div className="">
                                                            {/* <img className='rounded mx-auto d-block' src={imgHP} alt="Método HP" height="100px" /> */}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <br />

                                                <div className="twitter-bs-wizard">
                                                    <Form>
                                                        {this.state.eventoCodigo ? 
                                                        <>
                                                            <h3 className="">{this.state.eventoNome}</h3>
                                                            <span className="mb-4 text-center font-size-20">Lista de convidados (Checkin)</span>
                                                            <br />
                                                            <br />

                                                            <div className="table-responsive">
                                                                { this.state.convidadosArray.length > 0 ? <>
                                                                    <Table hover className="mb-0 table-centered table-nowrap">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Participante</th>
                                                                                <th>Área</th>
                                                                                <th>Tipo</th>
                                                                                <th>Whats</th>
                                                                                <th>Documento</th>
                                                                                <th>Mentorado</th>
                                                                                <th>Executivo</th>
                                                                                <th className='text-center'>Opcões</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {this.state.convidadosArray.map((convidado) => {
                                                                                return (
                                                                                    <tr>
                                                                                        <td title={convidado.area ? convidado.area : 'Nenhuma área cadastrada!'} role="button">
                                                                                            {this.getParticipante(convidado)}
                                                                                        </td>
                                                                                        <td role="button" title={convidado.area ? convidado.area : 'Nenhuma área cadastrada!'}>{this.truncate(convidado.area, 20)}</td>
                                                                                        <td>{this.getTipo(convidado)}</td>
                                                                                        <td>
                                                                                            {convidado.whatsapp ?
                                                                                                <Link 
                                                                                                    to={{pathname: `https://wa.me/55${convidado.whatsapp.replace(/[^0-9]/g,'')}`}} 
                                                                                                    className="btn btn-success btn-rounded btn-sm"
                                                                                                    target="_blank"
                                                                                                    title={convidado.whatsapp}
                                                                                                >
                                                                                                    <i className="mdi mdi-whatsapp font-size-18"></i>
                                                                                                </Link>
                                                                                                : <></> 
                                                                                            }
                                                                                        </td>
                                                                                        <td>{convidado.documento}</td>
                                                                                        <td>{convidado.mentorado}</td>
                                                                                        <td>{convidado.executivo}</td>
                                                                                        <td>
                                                                                            <div className="d-grid">
                                                                                                {convidado.status === 'aguardando_evento'? <>
                                                                                                    <Button
                                                                                                        color="info"
                                                                                                        outline
                                                                                                        className="waves-effect waves-light me-1 btn-rounded"
                                                                                                        onClick={() => this.checkinConvidado(convidado.leads_id, 'convidado_presente', convidado.mentorados_id)}
                                                                                                    >
                                                                                                        Checkin
                                                                                                    </Button>
                                                                                                </> : <>
                                                                                                    <Button
                                                                                                        color="success"
                                                                                                        outline
                                                                                                        className="waves-effect waves-light me-1 btn-rounded"
                                                                                                        onClick={() => this.checkinConvidado(convidado.leads_id, 'aguardando_evento', convidado.mentorados_id)}
                                                                                                    >
                                                                                                        Presente
                                                                                                    </Button>
                                                                                                </>}
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            })}
                                                                            
                                                                        </tbody>
                                                                    </Table>
                                                                </> : <>
                                                                </>}
                                                                
                                                            </div>
                                                            <hr />
                                                            <div>
                                                                <div className="d-grid mb-3">
                                                                    {this.state.eventoConvidados > this.state.convidadosArray.length ? <>
                                                                        <Button
                                                                            color="primary"
                                                                            className="btn btn-primary btn-lg btn-block"
                                                                            onClick={this.controlarModalVenda}
                                                                        >
                                                                            Cadastrar convidado
                                                                        </Button>
                                                                    </> : <>
                                                                        
                                                                    </>}
                                                                    
                                                                </div>
                                                            </div>
                                                        </> : <>
                                                            <Row>
                                                                <Col lg="12">
                                                                    <div className="mb-3">
                                                                        <h2 className="form-label">Digite o código do evento</h2>
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                                <Col lg="12">
                                                                    <div className="mb-3">
                                                                        <Input type="text" className="form-control" id="codigo-checkin"/>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <hr />
                                                            <div>
                                                                <div className="d-grid mb-3">
                                                                    <Button
                                                                        color="primary"
                                                                        type="button"
                                                                        className="btn btn-info btn-lg btn-block"
                                                                        onClick={() => this.login()}
                                                                    >
                                                                        INICIAR CHECKIN
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </>}
                                                    </Form>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    </Row>
                                </> : <>
                                    <Row className="justify-content-md-center">
                                        <Col xs={12} sm={12} md={12}>
                                            {this.state.loading ? <>
                                                <div className="">
                                                    <img className='justify-content-center rounded mx-auto d-block' src={imgLoading} alt="Método HP" height="200px" />
                                                </div>
                                            </> : <>
                                                <Col lg={4}>
                                                    <Card outline color="sucess" className="border">
                                                        <CardHeader className="bg-transparent">
                                                            <h5 className="my-0 text-success"><i className="mdi mdi-calendar-month-outline me-3"></i>Gestão de Eventos</h5>
                                                        </CardHeader>
                                                        <CardBody>
                                                            <CardTitle className="h4">Recado importante</CardTitle>
                                                            <CardText>O evento que procura não está disponivel para envio de convites. Avise o seu gerente de sucesso.</CardText>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </>}
                                        </Col>
                                    </Row>
                                </>}
                                
                                
                            </div>
                        </Col>
                    </Row>
                </div>

                <Modal
                    isOpen={this.state.modal_venda}
                    toggle={this.controlarModalVenda}
                    className="modal-dialog-centered"
                >
                <ModalHeader toggle={() => this.setState({ modal_venda: false })}>
                    Cadastro do convidado
                </ModalHeader>
                <ModalBody>

                    <Row className="mb-3">
                        <Label className="form-label">Nome</Label>
                        <Col md={12}>
                            <Input id="idConvidado" type="text" style={{ display: 'none' }}/>
                            <Input id="nome" type="text" />
                        </Col>  
                    </Row>

                    <Row className="mb-3"> 
                        <Label className="form-label">WhatsApp</Label>
                        <Col md={12}>
                            <Form.Control
                                as={InputMask}
                                className="form-control"
                                mask="(99) 9 9999-9999"
                                id="whatsapp"
                            />
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Label className="form-label">RG</Label>
                        <Col md={12}>
                            <Input id="documento" type="text"/>
                        </Col>  
                    </Row>

                    <Row className="mb-3">
                        <Label className="form-label">Área de atuação</Label>
                        <Col md={12}>
                            <Input id="area" type="text"/>
                        </Col>  
                    </Row>

                </ModalBody>
                    <Col lg="12" style={{padding: '0 20px'}}>
                        <div className="d-grid mb-3">
                            <Button
                                color="success"
                                className="btn btn-primary btn-lg btn-block"
                                onClick={() => this.cadastrarConvidado()}
                            >
                                Salvar convidado
                            </Button>
                        </div>
                    </Col>
                
                </Modal>
            </React.Fragment>
        );
    }
}

export default Checkin;