import React, { Component } from "react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withNamespaces } from 'react-i18next';

import { connect } from "react-redux";
import {
    changeLayout,
    changeLayoutWidth,
    changeSidebarTheme,
    changeSidebarType,
    changePreloader
} from "../../store/actions";

import img1 from "../../assets/images/parceiros/takeda.png";
import img2 from "../../assets/images/parceiros/amgen.png";
import img3 from "../../assets/images/parceiros/roche.png";
import img4 from "../../assets/images/parceiros/itaci.png";

class SidebarContent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            imagem: ''
        };

    }

    componentDidMount() {
        this.initMenu();
        this.imagensDinamicas();
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            if (this.props.type !== prevProps.type) {
                this.initMenu();
            }
        }
    }

    
    getLink(image) {
     if(image === img1) {
        return "https://www.takeda.com/pt-br"
     }
     if(image === img2) {
        return "https://www.amgen.com.br/"
     }
     if(image === img3) {
        return "https://www.roche.com.br"
     }
     if(image === img4) {
        return "https://www.itaci.org.br/"
     }
    }

    imagensDinamicas() {
      var arr = [img1, img2,
        img3, img4];
        /*eslint-disable */
        this.state.imagem = (arr[Math.floor(Math.random() * arr.length)]);
      }
        
    

    initMenu() {
        new MetisMenu("#side-menu");

        var matchingMenuItem = null;
        var ul = document.getElementById("side-menu");
        var items = ul.getElementsByTagName("a");
        for (var i = 0; i < items.length; ++i) {
            if (this.props.location.pathname === items[i].pathname) {
                matchingMenuItem = items[i];
                break;
            }
        }
        if (matchingMenuItem) {
            this.activateParentDropdown(matchingMenuItem);
        }
    }

    activateParentDropdown = item => {
        item.classList.add("active");
        const parent = item.parentElement;

        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show");

                const parent3 = parent2.parentElement;

                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement;
                    if (parent4) {
                        parent4.classList.add("mm-active");
                    }
                }
            }
            return false;
        }
        return false;
    };

    render() {
        return (
            <React.Fragment>
                <div id="sidebar-menu">

                    <ul className="metismenu list-unstyled" id="side-menu">
                        <li className="menu-title">{this.props.t('Menu')}</li>

                        <li>
                            <Link to="/dashboard">
                                <i className="ri-dashboard-line"></i>
                                <span className="ms-1">Menu</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/rumo">
                                🟢
                                <span className="ms-1">O Rumo</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/horas">
                                🟢
                                <span className="ms-1">Horas</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/recursos">
                                🟢
                                <span className="ms-1">Gestão de Recursos</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/network">
                                🟢
                                <span className="ms-1">Network</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/modelagem">
                                🟢
                                <span className="ms-1">Modelagem</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/conhecimento">
                                🟢
                                <span className="ms-1">Conhecimento</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="#">
                                🔴
                                <span className="ms-1">Áreas da vida</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="#">
                                🔴
                                <span className="ms-1">Score</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/treinamento">
                                🟢
                                <span className="ms-1">Treinamento</span>
                            </Link>
                        </li>
                        {/* <li>
                            <Link to={{ pathname: "https://www.instagram.com/janleysoares/" }} target="_blank">
                                🟢
                                <span className="ms-1">Sugestões</span>
                            </Link>
                        </li> */}
                    </ul>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    return { ...state.Layout };
};

export default withRouter(connect(mapStatetoProps, {
    changeLayout,
    changeSidebarTheme,
    changeSidebarType,
    changeLayoutWidth,
    changePreloader
})(withNamespaces()(SidebarContent)));
