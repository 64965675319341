import React, { Component } from "react";
import moment from 'moment';

import { DateRangePicker, Stack, CustomProvider } from 'rsuite';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';

import { Row, Col, Card, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Progress } from "reactstrap";
import { Link } from "react-router-dom";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import { ToastContainer, toast } from 'react-toastify';

import ToolkitProvider from 'react-bootstrap-table2-toolkit';

import api from '../../../services/api';

import Breadcrumbs from "../../../components/Common/Breadcrumb";
import PredefinedRanges from '../../../components/PredefinedRanges';
import "../../../assets/scss/datatables.scss";

class Modelagem extends Component {
    constructor(props) {
      super(props)
      this.state = {
        breadcrumbItems: [
          { title: "Menu", link: "/dashboard" },
          { title: "Modelagem", link: "#" },
        ],
        page: 1,
        sizePerPage: 10,
        productData: [],
        acaoData: [],
        eventoData: [],
        eventoLeadData: [],
        produtosData: [],
        visible: false,
        modal_cadastro: false,
        modal_apagar: false,
        modal_acao: false,
        modal_evento: false,
        filtro: '',
        url: '/modelagem',
        total_iniciar: '0',
        total_andamento: '0',
        total_concluido: '0',
        total_pausado: '0',
        total_geral: '0',
        filtroCategoria: '',
        mesAtual: [startOfMonth(new Date()), endOfMonth(new Date())],
      }

      this.controlarModal = this.controlarModal.bind(this);
    };

    setTotal(data) {
      console.log(data);
      this.setState({
        total_iniciar: data.iniciar
      });
      this.setState({
        total_andamento: data.andamento
      });
      this.setState({
        total_concluido: data.concluido
      });
      this.setState({
        total_pausado: data.pausado
      });
    }

    formatarValor(valor) {
      return valor ? parseFloat(valor).toLocaleString('pt-br', {minimumFractionDigits: 2}): '0,00';
    }

    setZerarTotal() {
      this.setState({
        total_comissoes: '0',
        total_pago: '0',
        total_pendente: '0',
      })
    }

    pesquisar() {
      const valorPesquisa = this.getValueId('valorPesquisar');
      
      if (document.getElementsByClassName("page-link").length > 0) {
        document.getElementsByClassName("page-link")[0].click();
      }
      
      this.setState({
        filtro: valorPesquisa
      });

      this.buscarDados();
    }

    async buscarDados() {
      const options = this.getOptions();

      let pesquisa = '';
      if (this.getValueId('valorPesquisar')) {
        pesquisa = this.getValueId('valorPesquisar');
      }

      let filtroCategoria = '';
      if (this.getValueId('filtroCategoria')) {
        filtroCategoria = this.getValueId('filtroCategoria');
      }

      let filtro = [];
  
      const range = this.state.mesAtual;
      if (range) {
        filtro = range;
      }

      const inicio =  moment(filtro[0]).format('YYYY-MM-DD');
      const fim = moment(filtro[1]).format('YYYY-MM-DD');

      const { data } = await api.get(`${this.state.url}?filtro=${pesquisa}&filtroCategoria=${filtroCategoria}&inicio=${inicio}&fim=${fim}`, options);

      this.setState({
        productData: data,
        total_geral: data.length
      });

      this.buscarTotal();
    }

    setFiltroCategoria(categoria) {
      this.setValueId('filtroCategoria', categoria);

      if (categoria === this.state.filtroCategoria) {
        this.setState({
          filtroCategoria: ''
        });
        this.setValueId('filtroCategoria', '');
      } else {
        this.setState({
          filtroCategoria: categoria
        });
      }

      this.buscarDados();
    }

    async buscarTotal() {
      try {
        const options = this.getOptions();

        let filtro = [];
    
        const range = this.state.mesAtual;
        if (range) {
          filtro = range;
        }
  
        const inicio =  moment(filtro[0]).format('YYYY-MM-DD');
        const fim = moment(filtro[1]).format('YYYY-MM-DD');
  
        const { data } = await api.get(`/modelagem/total?inicio=${inicio}&fim=${fim}`, options);
  
        //this.setZerarTotal();
        this.setTotal(data)
      } catch (error) {
        
      }
    }

    formatar(data) {
      const dataFormatada =
        process.env.NODE_ENV === 'development'
          ? moment(data).format('DD/MM/YYYY')
          : moment(data).add(1, 'days').format('DD/MM/YYYY');

      return dataFormatada
    }

    formatarParaEdicao(data) {
      const dataFormatada =
        process.env.NODE_ENV === 'development'
          ? moment(data).format('YYYY-MM-DD')
          : moment(data).add(1, 'days').format('YYYY-MM-DD');

      return dataFormatada
    }

    getOptions() {
        let obj = '';
        if (localStorage.getItem("authUser")) {
        obj = JSON.parse(localStorage.getItem("authUser"));
        }

        const options = {
        headers: {"Authorization" : `Bearer ${obj.token}`}
        }

        return options;
    }

    cadastrarOuEditar() {
      const existeId = document.getElementById('idCadastro').value;

      if (existeId) {
        this.atualizacao();
      } else {
        this.cadastro();
      }
    }

    async abrirModalEdicao(idItem) {
      const options = this.getOptions();
      await api.get(`${this.state.url}/${idItem}`, options)
      .then(({ data })=> {

        this.controlarModal();

        setTimeout(() => {
            this.setValueId('idCadastro', idItem);
            this.setValueId('nome', data.nome);
            this.setValueId('reacao', data.reacao);
            this.setValueId('pensamento', data.pensamento);
            this.setValueId('sentimento', data.sentimento);
            this.setValueId('cria', data.cria);
            this.setValueId('vende', data.vende);
            this.setValueId('ensina', data.ensina);
            this.setValueId('aprende', data.aprende);
            this.setValueId('status_origem', data.status);
            this.setValueId('progresso', data.progresso);
            this.setValueId('prioridade', data.prioridade);
            this.setValueId('data_inicio', this.formatarParaEdicao(data.data_inicio));
            this.setValueId('data_fim', this.formatarParaEdicao(data.data_fim));
        }, 500);
      });
    }

    getValueId(nomeId) {
      return document.getElementById(nomeId).value;
    }

    setValueId(nomeId, valor) {
      document.getElementById(nomeId).value = valor;
    }

    controlarModal() {
      this.setState(prevState => ({
        modal_cadastro: !prevState.modal_cadastro
      }));
      this.removeBodyCss();
    }

    removeBodyCss() {
      document.body.classList.add("no_padding");
    }

    componentDidMount() {
      this.buscarDados();
    }

    async cadastro() {
      try {
        const novoItem = this.getObjeto();
        if (!this.validarCampos(novoItem)) {
          return false;
        }
        const options = this.getOptions();
        await api.post(this.state.url, novoItem, options)
        .then(() => {
          this.controlarModal();
          toast.success('O cadastro foi um sucesso.');
          this.buscarDados();
        });

      } catch (error) {
        toast.error('Não foi possível fazer o cadastro. Tente novamente mais tarde.');
      }
    }

    getObjeto() {
      return {
        nome: this.getValueId('nome'),
        reacao: this.getValueId('reacao'),
        pensamento: this.getValueId('pensamento'),
        sentimento: this.getValueId('sentimento'),
        cria: this.getValueId('cria'),
        vende: this.getValueId('vende'),
        ensina: this.getValueId('ensina'),
        aprende: this.getValueId('aprende'),
        status: this.getValueId('status_origem'),
        progresso: this.getValueId('progresso'),
        prioridade: this.getValueId('prioridade'),
        data_inicio: this.getValueId('data_inicio'),
        data_fim: this.getValueId('data_fim')
      };
    }

    validarCampos(item) {
      const { nome, status, progresso, prioridade, data_inicio, data_fim } = item;

      if (!nome) {
        toast.error('Campo nome obrigatório');
        return false;
      }

      if (!status) {
        toast.error('Campo status obrigatório');
        return false;
      }

      if (!progresso) {
        toast.error('Campo progresso obrigatório');
        return false;
      }

      if (!prioridade) {
        toast.error('Campo prioridade obrigatório');
        return false;
      }

      if (!data_inicio) {
        toast.error('Campo Data início obrigatório');
        return false;
      }

      if (!data_fim) {
        toast.error('Campo Data fim obrigatório');
        return false;
      }

      return true;
    }

    async atualizacao() {
      try {
        const novoItem = this.getObjeto();
        if (!this.validarCampos(novoItem)) {
          return false;
        }

        const idCadastro = this.getValueId('idCadastro');

        const options = this.getOptions();
        await api.put(`/modelagem/${idCadastro}`, novoItem, options)
        .then(() => {
          toast.success('Atualização feita com sucesso!');
          this.controlarModal();
          this.buscarDados();
        });

      } catch (error) {
        toast.error('Não foi possivel atualizar, tente novamente mais tarde.');
      }
    }

    boxSelecao = (tipo) => {
      return this.state.filtroCategoria === tipo ? { backgroundColor: '#444444' } : { backgroundColor: '' }
    }

  render() {

    const columns = [
    {
        dataField: 'id',
        text: 'ID',
        sort: true
    }, {
      dataField: 'nome',
      text: 'Nome',
      sort: true
    }, {
      dataField: 'progresso',
      text: 'Progresso',
      sort: true,
      formatter: (cellContent, row) => (
          <React.Fragment>
              <b>{row.progresso}%</b>
              <Progress color="info" value={row.progresso} >
              </Progress>
          </React.Fragment>
      )
    }, {
        dataField: 'prioridade',
        text: 'Prioridade',
        sort: true,
        formatter: (cellContent, row) => (
            <React.Fragment>
                {row.prioridade ? <>
                  <div className="badge badge-soft-warning font-size-24">
                    <b>{row.prioridade}</b>
                  </div>
                </> : <>
                  <div className="badge badge-soft-warning font-size-12">
                    <b>{row.prioridade}</b>
                  </div>
                </>}
            </React.Fragment>
        )
    }, {
        dataField: 'status',
        text: 'Status',
        sort: true,
        formatter: (cellContent, row) => (
            <React.Fragment>
                {row.status ? <>
                  {row.status}
                </> : <>
                  {row.status}
                </>}
            </React.Fragment>
        )
    }, {
      dataField: 'data_inicio',
      text: 'Inicio',
      sort: true,
      formatter: (cellContent, row) => (
          <React.Fragment>
              {row.data_inicio ? <>
                {this.formatar(row.data_inicio)}
              </> : <>
                {this.formatar(row.data_inicio)}
              </>}
          </React.Fragment>
      )
    }, {
      dataField: 'data_fim',
      text: 'Fim',
      sort: true,
      formatter: (cellContent, row) => (
          <React.Fragment>
              {row.data_fim ? <>
                {this.formatar(row.data_fim)}
              </> : <>
                {this.formatar(row.data_fim)}
              </>}
          </React.Fragment>
      )
    }, {
      dataField: 'id',
      isDummyField: true,
      text: "Opções",
      formatter: (cellContent, row) => (
        <React.Fragment>
            <Link 
                to="#" 
                onClick={() => this.abrirModalEdicao(row.id)}
                className="me-1 btn btn-info btn-rounded btn-sm"
                title="Registrar venda"
            >
                <i className="mdi mdi-pencil-box-multiple font-size-18"></i>
            </Link>
            {/* <Link 
                to="#" 
                className="me-1 btn btn-danger btn-rounded btn-sm"
                title="Cadastrar ação"
            >
                <i className="mdi mdi-trash-can-outline font-size-18"></i>
            </Link> */}
        </React.Fragment>
      ),
    }];

    const defaultSorted = [{
      dataField: 'categorias',
      order: 'asc'
    }];

    const pageOptions = {
      sizePerPage: 10,
      totalSize: this.state.productData.length, // replace later with size(customers),
      custom: true,
    }

    return (
      <React.Fragment>
        <ToastContainer />
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Modelagem" breadcrumbItems={this.state.breadcrumbItems} />
            <Input id="filtroCategoria" type="text" style={{ display: 'none' }}/>
            <div className="row">
                <div className="col-md-3">
                  <Link to="#" onClick={() => this.setFiltroCategoria('A iniciar')}>
                    <div className="card" style={this.boxSelecao('A iniciar')}>
                        <div className="card-body">
                            <div className="d-flex">
                            <div className="flex-1 overflow-hidden">
                                <h5>A iniciar</h5>
                                <h5 className="mb-0 font-size-24">{this.state.total_iniciar ? this.state.total_iniciar : 0}</h5>
                            </div>
                            <div className="text-warning font-size-24">▶️</div>
                            </div>
                        </div>
                    </div>
                  </Link>
                </div>
                <div className="col-md-3">
                  <Link to="#" onClick={() => this.setFiltroCategoria('Em andamento')}>
                    <div className="card" style={this.boxSelecao('Em andamento')}>
                        <div className="card-body">
                            <div className="d-flex">
                              <div className="flex-1 overflow-hidden">
                                  <h5>Em andamento</h5>
                                  <h5 className="mb-0 font-size-24">{this.state.total_andamento ? this.state.total_andamento : 0}</h5>
                              </div>
                              <div className="text-warning font-size-24">⏱️</div>
                            </div>
                        </div>
                    </div>
                  </Link>
                </div>
                <div className="col-md-3">
                  <Link to="#" onClick={() => this.setFiltroCategoria('Concluído')}>
                    <div className="card" style={this.boxSelecao('Concluído')}>
                        <div className="card-body">
                            <div className="d-flex">
                              <div className="flex-1 overflow-hidden">
                                  <h5>Concluído</h5>
                                  <h5 className="mb-0 font-size-24">{this.state.total_concluido ? this.state.total_concluido : 0}</h5>
                              </div>
                              <div className="text-success font-size-24">✅</div>
                            </div>
                        </div>
                    </div>
                  </Link>
                </div>
                <div className="col-md-3">
                  <Link to="#" onClick={() => this.setFiltroCategoria('Pausado')}>
                    <div className="card" style={this.boxSelecao('Pausado')}>
                        <div className="card-body">
                            <div className="d-flex">
                              <div className="flex-1 overflow-hidden">
                                  <h5>Pausado</h5>
                                  <h5 className="mb-0 font-size-24">{this.state.total_pausado ? this.state.total_pausado : 0}</h5>
                              </div>
                              <div className="text-success font-size-24">🟥</div>
                            </div>
                        </div>
                    </div>
                  </Link>
                </div>
            </div>

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='usuarios_id'
                      columns={columns}
                      data={this.state.productData}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='usuarios_id'
                          columns={columns}
                          data={this.state.productData}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>

                              <Row className="mb-2">
                                <Col md="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <Row className="mb-3">
                                          <Col md={10}>
                                              <Input id="valorPesquisar" type="text" placeholder="Pesquisar" onChange={() => this.pesquisar()}/>
                                          </Col>
                                      </Row>
                                      
                                      <i className="search-box chat-search-box" />
                                    </div>
                                  </div>
                                </Col>
                                <Col sm="4">
                                  <div className="text-sm-end">
                                    <CustomProvider theme='dark'>
                                      <Stack direction="column" spacing={8} alignItems="flex-start">
                                        <DateRangePicker
                                          ranges={PredefinedRanges}
                                          placeholder="Selecionar data"
                                          style={{ width: 300 }}
                                          id="calendario"
                                          value={this.state.mesAtual}
                                          onChange={ 
                                            event => {
                                              setTimeout(() => {
                                                this.setState({ mesAtual : event });
                                                this.buscarDados();
                                              }, 0);
                                            }
                                          }
                                        />
                                      </Stack>
                                    </CustomProvider>
                                  </div>
                                </Col>
                                <div className="col-sm-4">
                                  <Row>
                                    <div className="text-sm-end">
                                      <button type="button" onClick={() => this.controlarModal()} className="btn-rounded mb-2 me-2 btn btn-success"><i className="mdi mdi-plus-thick me-1 font-size-18"></i> CADASTRAR</button>
                                    </div>
                                  </Row>
                                </div>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField='idusuarios'
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      ref={(n) => (this.node = n)}
                                      classes={
                                        "table align-middle table-nowrap table-hover"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />

                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>

        <Modal
          isOpen={this.state.modal_cadastro}
          toggle={this.controlarModal}
        >
          <ModalHeader toggle={() => this.setState({ modal_cadastro: false })}>
              Modelagem
          </ModalHeader>
          <ModalBody>
            <Row className="mb-3">
                <Col md={12}>
                    <Input id="idCadastro" type="text" style={{ display: 'none' }}/>
                    <Input id="data_pagamento" type="text" style={{ display: 'none' }}/>
                </Col>  
            </Row>
            <Row className="mb-3">
                <Label className="form-label">Qual o nome da pessoa ou empresa que você vai modelar?</Label>
                <Col md={12} disabled>
                    <Input id="nome" type="text" placeholder=""/>
                </Col>  
            </Row>
            <Row className="mb-3">
                <Label className="form-label">1) Como ele reage?</Label>
                <Col md={12} disabled>
                    <Input id="reacao" type="textarea" placeholder="Digite aqui..."/>
                </Col>  
            </Row>
            <Row className="mb-3">
                <Label className="form-label">2) Como ele pensa?</Label>
                <Col md={12} disabled>
                    <Input id="pensamento" type="textarea" placeholder="Digite aqui..."/>
                </Col>  
            </Row>
            <Row className="mb-3">
                <Label className="form-label">3) Como ele sente?</Label>
                <Col md={12} disabled>
                    <Input id="sentimento" type="textarea" placeholder="Digite aqui..."/>
                </Col>  
            </Row>
            <Row className="mb-3">
                <Label className="form-label">4) Como ele cria?</Label>
                <Col md={12} disabled>
                    <Input id="cria" type="textarea" placeholder="Digite aqui..."/>
                </Col>  
            </Row>
            <Row className="mb-3">
                <Label className="form-label">5) Como ele vende?</Label>
                <Col md={12} disabled>
                    <Input id="vende" type="textarea" placeholder="Digite aqui..."/>
                </Col>  
            </Row>
            <Row className="mb-3">
                <Label className="form-label">6) Como ele ensina?</Label>
                <Col md={12} disabled>
                    <Input id="ensina" type="textarea" placeholder="Digite aqui..."/>
                </Col>  
            </Row>
            <Row className="mb-3">
                <Label className="form-label">7) Como ele aprende?</Label>
                <Col md={12} disabled>
                    <Input id="aprende" type="textarea" placeholder="Digite aqui..."/>
                </Col>  
            </Row>
            <Row className="mb-3">
                <Label className="form-label">Status</Label>
                <Col md={12}>
                    <select id="status_origem" className="form-control">
                        <option value="">Selecionar...</option>
                        <option value="A iniciar">A iniciar</option>
                        <option value="Em andamento">Em andamento</option>
                        <option value="Concluído">Concluído</option>
                        <option value="Pausado">Pausado</option>
                    </select>
                </Col>
            </Row>
            <Row className="mb-3">
                <Label className="form-label">Progresso</Label>
                <Col md={12}>
                    <select id="progresso" className="form-control">
                        <option value="">Selecionar...</option>
                        <option value="0">0%</option>
                        <option value="10">10%</option>
                        <option value="20">20%</option>
                        <option value="30">30%</option>
                        <option value="40">40%</option>
                        <option value="50">50%</option>
                        <option value="60">60%</option>
                        <option value="70">70%</option>
                        <option value="80">80%</option>
                        <option value="90">90%</option>
                        <option value="100">100%</option>
                    </select>
                </Col>
            </Row>
            <Row className="mb-3">
                <Label className="form-label">Prioridade</Label>
                <Col md={12}>
                    <select id="prioridade" className="form-control">
                        <option value="">Selecionar...</option>
                        <option value="10">10</option>
                        <option value="9">9</option>
                        <option value="8">8</option>
                        <option value="7">7</option>
                        <option value="6">6</option>
                        <option value="5">5</option>
                        <option value="4">4</option>
                        <option value="3">3</option>
                        <option value="2">2</option>
                        <option value="1">1</option>
                    </select>
                </Col>
            </Row>
            <Row className="mb-3">
                <Label className="form-label">Inicio</Label>
                <Col md={12}>
                    <Input id="data_inicio" type="date"/>
                </Col>  
            </Row>
            <Row className="mb-3">
                <Label className="form-label">Fim</Label>
                <Col md={12}>
                    <Input id="data_fim" type="date"/>
                </Col>  
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              onClick={this.controlarModal}
              color="light"
              className="waves-effect"
            >
                Fechar
            </Button>
            <Button
              color="primary"
              className="btn btn-primary btn-block"
              onClick={() => this.cadastrarOuEditar()}
            >
              Salvar
            </Button>
          </ModalFooter>
        </Modal>

      </React.Fragment>
    )
  }
}

export default Modelagem;