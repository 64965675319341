import React, { Component } from 'react';
import { Container } from "reactstrap";

import { Link } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';

class Treinamento extends Component {
    constructor(props) {
        super(props);
        this.state={
            breadcrumbItems : [
                { title : "Menu", link : "/dashboard" },
                { title : "Treinamento", link : "#" },
            ],
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                    <Breadcrumbs title="Treinamento" breadcrumbItems={this.state.breadcrumbItems} />

                    Mentoria o Jogo da Vida. <br />
                    <br />
                    ✅ 01 - O RUMO <br />
                    <br />
                    <Link
                        to={{ pathname: "https://youtu.be/ZDkHDbswv50" }}
                        target="_blank"
                        className='btn btn-danger'
                    >
                        Assistir
                    </Link>
                    <br />
                    <br />
                    ✅ 02 - HORAS <br />
                    <br />
                    <Link
                        to={{ pathname: "https://youtu.be/DvGXer3AozQ" }}
                        target="_blank"
                        className='btn btn-danger'
                    >
                        Assistir
                    </Link>
                    <br />
                    <br />
                    ✅ 03 - RECURSOS <br />
                    <br />
                    <Link
                        to={{ pathname: "https://youtu.be/QJRgY1K65wk" }}
                        target="_blank"
                        className='btn btn-danger'
                    >
                        Assistir
                    </Link>
                    <br />
                    <br />
                    ✅ 04 - NETWORK <br />
                    <br />
                    <Link
                        to={{ pathname: "https://youtu.be/_X-PTsMeUEU" }}
                        target="_blank"
                        className='btn btn-danger'
                    >
                        Assistir PARTE 1
                    </Link>
                    <br />
                    <br />
                    <Link
                        to={{ pathname: "https://youtu.be/T3qDIdEQTKE" }}
                        target="_blank"
                        className='btn btn-danger'
                    >
                        Assistir PARTE 2
                    </Link>
                    <br />
                    <br />
                    ✅ 05 - MODELAGEM <br />
                    <br />
                    <Link
                        to={{ pathname: "https://youtu.be/WYa2-nPBsJk" }}
                        target="_blank"
                        className='btn btn-danger'
                    >
                        Assistir
                    </Link>
                    <br />
                    <br />
                    ✅ 06 - CONHECIMENTO <br />
                    <br />
                    <Link
                        to={{ pathname: "https://youtu.be/QihzHQbtTkg" }}
                        target="_blank"
                        className='btn btn-danger'
                    >
                        Assistir
                    </Link>
                    <br />
                    <br />
                    ✅ 07 - ÁREAS DA VIDA <br />
                    <br />
                    <Link
                        to={{ pathname: "https://youtu.be/I7ubKh59abc" }}
                        target="_blank"
                        className='btn btn-danger'
                    >
                        Assistir
                    </Link>
                    <br />
                    <br />
                    ✅ 08 - SCORE <br />
                    <br />
                    <Link
                        to={{ pathname: "https://youtu.be/YEDJbS-sM5w" }}
                        target="_blank"
                        className='btn btn-danger'
                    >
                        Assistir
                    </Link>
                    <br />
                    <br />
                    ✅ 09 - DRIVERS PARA ACELERAR O SEU RESULTADO <br />
                    <br />
                    <Link
                        to={{ pathname: "https://youtu.be/hWfgkCc0TPU" }}
                        target="_blank"
                        className='btn btn-danger'
                    >
                        Assistir
                    </Link>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    </Container> 
                </div>
            </React.Fragment>
        );
    }
}

export default Treinamento;