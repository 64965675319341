import { 
  isSunday,
  isMonday,
  nextSunday,
  previousMonday,
  addMonths,
  addWeeks,
  endOfMonth,
  startOfMonth,
  addDays
}  from 'date-fns';

const PredefinedRanges = [
  {
    label: 'Hoje',
    value: [new Date(), new Date()],
    placement: 'left'
  },
  {
    label: 'Ontem',
    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
    placement: 'left'
  },
  {
    label: 'Semana passada',
    value: [
      isMonday(new Date()) ? addWeeks(new Date(), -1) : previousMonday(addWeeks(new Date(), -1)), 
      isSunday(new Date()) ? addWeeks(new Date(), -1) : nextSunday(addWeeks(new Date(), -1))
    ],
    placement: 'left'
  },
  {
    label: 'Semana atual',
    value: [
      isMonday(new Date()) ? new Date() : previousMonday(new Date()), 
      isSunday(new Date()) ? new Date() : nextSunday(new Date())
    ],
    placement: 'left'
  },
  {
    label: 'Mês passado',
    value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
    placement: 'left'
  },
  {
    label: 'Mês atual',
    value: [startOfMonth(new Date()), endOfMonth(new Date())],
    placement: 'left'
  },
  {
    label: 'Ano atual',
    value: [new Date(new Date().getFullYear(), 0, 1), new Date(new Date().getFullYear(), 11, 31)],
    placement: 'left'
  }
];
  
export default PredefinedRanges;