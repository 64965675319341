import React from "react";
import IntlCurrencyInput from "react-intl-currency-input";

const InputDinheiro = ({ id }) => {
    return (
        <IntlCurrencyInput 
            id={id}
            className="form-control"
            currency="BRL" 
            config={{
                locale: "pt-BR",
                formats: {
                    number: {
                    BRL: {
                        style: "currency",
                        currency: "BRL",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    },
                    },
                },
            }}
        />
    );
};

export default InputDinheiro;
