import React, { Component } from 'react';
import { Button, Card, CardBody, Col, Container, Form, Label, Row } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Link } from "react-router-dom";

// import api from '../../services/api';

import Breadcrumbs from '../../components/Common/Breadcrumb';
import { ToastContainer } from 'react-toastify';
import Avatar from 'react-avatar';
// import AvatarEditor from 'react-avatar-editor'

class Perfil extends Component {
    constructor(props) {
        super(props);
        this.state={
            breadcrumbItems : [],
            name: '',
            email: '',
            documentType: '',
            document: '',
            instagram: '',
            whatsapp: '',
            cep: '',
            city: '',
            number: '',
            block: '',
            addressState: '',
            country: '',
            complement: '',
            selectedFile: null,
            isLoading: false,
            avatarIsLoading: false,
            pageIsLoading: true,
            resizeAvatar: false,
            username: '',
            nomeCompleto: '',
            avatar: ''
        }
    }

    componentDidMount() {
        let username = "Logado";
        let nomeCompleto = '';
        let avatar = '';

        if (localStorage.getItem("authUser")) {
            const obj = JSON.parse(localStorage.getItem("authUser"));
            username = obj.nome.split(' ')[0];
            nomeCompleto = obj.nome;
            avatar = obj.avatar;
        }
    
        this.setState({
            username: username,
            nomeCompleto: nomeCompleto,
            avatar: avatar,
        });
    
    }


    render() {
        return (
            <React.Fragment>
                <ToastContainer />
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="Perfil" breadcrumbItems={this.state.breadcrumbItems} />

                        <Row>
                            <Col xl="6">
                                <Card>
                                    <CardBody>
                                        <h4 className="card-title">Atualizar senha</h4>
                                        <p className="card-title-desc">Insira uma senha de no mínimo 6 caracteres.</p>
                                        <AvForm className="needs-validation" >
                                            <Row>
                                                <Col md="12">
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="validationCustom01">Digitar nova senha</Label>
                                                        <AvField
                                                        name="firstname"
                                                        type="password"
                                                        autoComplete="new-password"
                                                        errorMessage="Enter First Name"
                                                        className="form-control"
                                                        validate={{ required: { value: true } }}
                                                        id="validationCustom01"
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md="12">
                                                    <div className="mb-3">
                                                        <Label className="form-label" htmlFor="validationCustom02">Confirmar nova senha</Label>
                                                        <AvField
                                                        name="lastname"
                                                        type="password"
                                                        autoComplete="new-password"
                                                        errorMessage="Enter Last name"
                                                        className="form-control"
                                                        validate={{ required: { value: true } }}
                                                        id="validationCustom02"
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Button color="primary" type="submit">Atualizar senha</Button>
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col xl="6">
                                <Card>
                                    <CardBody>
                                        <h4 className="card-title">Foto perfil</h4>
                                        <p className="card-title-desc">Coloque uma foto de perfil e apareceça nos rankings e competições de venda.</p>
                                        <Form className="needs-validation" method="post" id="tooltipForm" onSubmit={this.handleSubmit}>
                                            <Row>
                                                <div className="text-center">
                                                    <Avatar 
                                                        className="avatar-sm mt-2 mb-4" 
                                                        name={this.state.nomeCompleto} 
                                                        src={this.state.avatar} 
                                                        size="100" 
                                                        round={true} 
                                                        maxInitials={2}
                                                    />
                                                    <div className="flex-1">
                                                    <h5 className="text-truncate">
                                                        <Link className="text-muted" to="#">
                                                            {this.state.nomeCompleto} 
                                                        </Link>
                                                    </h5>
                                                    </div>
                                                </div>
                                            </Row>
                                            <Button color="primary" type="submit">Atualizar foto</Button>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container> 
                </div>
            </React.Fragment>
        );
    }
}

export default Perfil;