import React, { Component } from "react";
import InputMask from 'react-input-mask';
import Form from 'react-bootstrap/Form';
import moment from 'moment';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';

import { Row, Col, Card, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Badge } from "reactstrap";
import { Link } from "react-router-dom";

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import { ToastContainer, toast } from 'react-toastify';

import ToolkitProvider from 'react-bootstrap-table2-toolkit';

import InputDinheiro from '../../../components/InputDinheiro';

import api from '../../../services/api';

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import "../../../assets/scss/datatables.scss";

class Leads extends Component {
    constructor(props) {
      super(props)
      this.state = {
        breadcrumbItems: [
          { title: "Menu", link: "/dashboard" },
          { title: "Leads", link: "#" },
        ],
        page: 1,
        sizePerPage: 10,
        productData: [],
        acaoData: [],
        eventoData: [],
        eventoLeadData: [],
        produtosData: [],
        visible: false,
        modal_standard: false,
        modal_apagar: false,
        modal_acao: false,
        modal_evento: false,
        filtro: '',
        filtroNiveis: '',
        filtroContatos: '',
        filtroOrigem: '',
        url: '/leads',
        totalleads: 0,
        fazercontato: 0,
        emcontato: 0,
        agendadozoom: 0,
        zoomok: 0,
        linkenviado: 0,
        emnegociacao: 0,
        propostarecusada: 0,
        vendaextraordinaria: 0,
        vendaemaberto: 0,
        semretorno: 0,
        vendasExecutivo: '0,00',
        comissao: '0,00',
        ranking: '',
        filtroCategoria: '',
      }

      this.controlarModal = this.controlarModal.bind(this);
      this.controlarModalApagar = this.controlarModalApagar.bind(this);
      this.controlarModalAcao = this.controlarModalAcao.bind(this);
      this.controlarModalEvento = this.controlarModalEvento.bind(this);
      this.controlarModalVenda = this.controlarModalVenda.bind(this);
    };

    async buscarDados() {
      const options = this.getOptions();

      let pesquisa = '';
      if (this.getValueId('valorPesquisar')) {
        pesquisa = this.getValueId('valorPesquisar');
      }

      let filtroCategoria = '';
      if (this.getValueId('filtroCategoria')) {
        filtroCategoria = this.getValueId('filtroCategoria');
      }

      let filtroNiveis = '';
      if (this.getValueId('filtroNiveis')) {
        filtroNiveis = this.getValueId('filtroNiveis');
      }

      let filtroOrigem = '';
      if (this.getValueId('filtroOrigem')) {
        filtroOrigem = this.getValueId('filtroOrigem');
      }

      let filtroContatos = '';
      if (this.getValueId('filtroContatos')) {
        filtroContatos = this.getValueId('filtroContatos');
      }

      console.log({
        'pesquisa': pesquisa, 
        'filtroCategoria': filtroCategoria, 
        'filtroNiveis': filtroNiveis, 
        'filtroOrigem': filtroOrigem, 
        'filtroContatos': filtroContatos
      });

      const { data } = await api.get(
        `${this.state.url}?filtro=${pesquisa}&filtroCategoria=${filtroCategoria}&filtroNiveis=${filtroNiveis}&filtroOrigem=${filtroOrigem}&filtroContatos=${filtroContatos}`,
        options
      );

      this.setState({
        productData: data,
        totalleads: data.length
      });

      this.buscarDadosTotal();
    }

    setFiltroCategoria(categoria) {
      this.setValueId('filtroCategoria', categoria);
      this.setState({
        filtroCategoria: categoria
      });

      this.buscarDados();
    }

    async buscarDadosAcao(idLead) {
      const options = this.getOptions();

      const { data } = await api.get(`${this.state.url}/acao/${idLead}`, options);

      this.setState({
        acaoData: data
      });
    }

    async buscarProdutos() {
      const options = this.getOptions();

      const { data } = await api.get(`/produtos/executivo`, options);

      this.setState({
        produtosData: data
      });
    }

    async buscarVendasExecutivo() {
      const options = this.getOptions();

      const { data } = await api.get(`/executivo/comissao`, options);

      const valorFormatado = data.valor_total;

      this.setState({
        vendasExecutivo: valorFormatado? parseFloat(valorFormatado).toLocaleString('pt-br', {minimumFractionDigits: 2}): '0,00',
        comissao: valorFormatado? parseFloat(valorFormatado).toLocaleString('pt-br', {minimumFractionDigits: 2}): '0,00',
      });
    }

    async buscarRankingExecutivo() {
      const options = this.getOptions();

      let inicio = moment(startOfMonth(new Date())).format('YYYY/MM/DD');
      let fim = moment(endOfMonth(new Date())).format('YYYY/MM/DD');
      const { data } = await api.get(`/vendas/executivo/ranking?inicio=${inicio}&fim=${fim}`, options);

      this.setState({
        ranking: data.ranking? data.ranking+'°🏆': '-',
      });
    }

    async buscarDadosEvento() {
      const options = this.getOptions();

      let inicio = moment(new Date()).format('YYYY/MM/DD');
      let fim = moment(endOfMonth(addMonths(new Date(), +2))).format('YYYY/MM/DD');

      const { data } = await api.get(`/eventos?inicio=${inicio}&fim=${fim}`, options);

      this.setState({
        eventoData: data
      });
    }

    async buscarDadosEventoLead(idLead) {
      const options = this.getOptions();

      const { data } = await api.get(`/eventos/lead/${idLead}`, options);

      this.setState({
        eventoLeadData: data
      });
    }

    async buscarDadosTotal() {
      const options = this.getOptions();

      let pesquisa = '';
      if (this.getValueId('valorPesquisar')) {
        pesquisa = this.getValueId('valorPesquisar');
      }

      const { data } = await api.get(`${this.state.url}/total?filtro=${pesquisa}`, options);

      this.setZerarTotal();
      data.map((objeto) => this.setTotal(objeto));
    }

    setTotal(data) {
      if (data.status === 'Fazer Contato') {
        this.setState({
          fazercontato: data.total
        });
        return;
      }

      if (data.status === 'Em contato') {
        this.setState({
          emcontato: data.total
        });
        return;
      }

      if (data.status === 'Agendado Zoom') {
        this.setState({
          agendadozoom: data.total
        });
        return;
      }

      if (data.status === 'Link enviado') {
        this.setState({
          linkenviado: data.total
        });
        return;
      }

      if (data.status === 'Em negociação') {
        this.setState({
          emnegociacao: data.total
        });
        return;
      } 

      if (data.status === 'Proposta Recusada') {
        this.setState({
          propostarecusada: data.total
        });
        return;
      } 

      if (data.status === 'Venda Extraordinária') {
        this.setState({
          vendaextraordinaria: data.total
        });
        return;
      }

      if (data.status === 'Venda em aberto') {
        this.setState({
          vendaemaberto: data.total
        });
        return;
      } 

      if (data.status === 'Sem retorno') {
        this.setState({
          semretorno: data.total
        });
        return;
      } 
    }

    setZerarTotal() {
      this.setState({
        fazercontato: 0,
        emcontato: 0,
        agendadozoom: 0,
        zoomok: 0,
        linkenviado: 0,
        emnegociacao: 0,
        propostarecusada: 0,
        vendaextraordinaria: 0,
        vendaemaberto: 0,
        semretorno: 0
      })
    }

    pesquisar() {
      const valorPesquisa = this.getValueId('valorPesquisar');
      const filtroNiveis = this.getValueId('filtroNiveis');
      const filtroContatos = this.getValueId('filtroContatos');
      const filtroOrigem = this.getValueId('filtroOrigem');
      
      if (document.getElementsByClassName("page-link").length > 0) {
        document.getElementsByClassName("page-link")[0].click();
      }
      
      this.setState({
        filtro: valorPesquisa,
        filtroNiveis: filtroNiveis,
        filtroContatos: filtroContatos,
        filtroOrigem: filtroOrigem
      });

      this.buscarDados();
    }

    getOptions() {
        let obj = '';
        if (localStorage.getItem("authUser")) {
        obj = JSON.parse(localStorage.getItem("authUser"));
        }

        const options = {
        headers: {"Authorization" : `Bearer ${obj.token}`}
        }

        return options;
    }

    getOptionsVenda() {
      let obj = '';
      if (localStorage.getItem("authUser")) {
      obj = JSON.parse(localStorage.getItem("authUser"));
      }

      const options = {
        headers: {
          "Authorization" : `Bearer ${obj.token}`,
          "Content-Type": "multipart/form-data"
        }
      }

      return options;
  }

    async abrirModalAcao(idItem) {
        const options = this.getOptions();
        await api.get(`${this.state.url}/${idItem}`, options)
        .then(({ data })=> {

          this.controlarModalAcao();

          setTimeout(() => {
              this.setValueId('idLead', idItem);
              this.setValueId('nome', data.nome);
              this.buscarDadosAcao(idItem);
          }, 500);
        });
    }

    async abrirModalEvento(idItem) {
      const options = this.getOptions();
      await api.get(`${this.state.url}/${idItem}`, options)
      .then(({ data })=> {

        this.controlarModalEvento();

        setTimeout(() => {
            this.setValueId('idLead', idItem);
            this.setValueId('nome', data.nome);
            this.buscarDadosEvento();
            this.buscarDadosEventoLead(idItem);
        }, 500);
      });
    }

    async abrirModalVenda(idItem) {
      const options = this.getOptions();
      await api.get(`${this.state.url}/${idItem}`, options)
      .then(({ data })=> {

        this.controlarModalVenda();

        setTimeout(() => {
            this.setValueId('idLead', idItem);
            this.setValueId('nome', data.nome);
            document.getElementById('data_venda').value = moment().format('YYYY-MM-DD');
            this.buscarProdutos();
        }, 500);
      });
    }

    async controlarModalEdicao(idItem) {
        const options = this.getOptions();
        await api.get(`${this.state.url}/${idItem}`, options)
        .then(({ data })=> {

          this.controlarModal();

          setTimeout(() => {
              this.setValueId('idItem', idItem);
              this.setValueId('nome', data.nome);
              this.setValueId('whatsapp', data.whatsapp);
              this.setValueId('instagram', data.instagram);
              this.setValueId('email', data.email);
              this.setValueId('origem', data.origem);
              this.setValueId('status1', data.status);
              this.setValueId('hp', data.hp);
              this.setValueId('nivel', data.nivel);
              this.setValueId('area', data.area);
          }, 500);
        });
    }

    cadastrarOuEditar() {
      const existeId = document.getElementById('idItem').value;

      if (existeId) {
        this.editarItem();
      } else {
        this.cadastrarItem();
      }
    }

    async cadastrarItem() {
      try {
        const novoItem = this.getObjeto();
        if (!this.validarCampos(novoItem)) {
          return false;
        }
        const options = this.getOptions();
        await api.post(this.state.url, novoItem, options)
        .then(() => {
          this.controlarModal();
          toast.success('Item cadastrado.');
          this.buscarDados();
        });

      } catch (error) {
        toast.error('Não foi possível cadastrar o item.');
      }
    }

    async editarItem() {
      try {
        const itemSelecionado = this.getObjeto();
    
        if (!this.validarCampos(itemSelecionado)) {
          return false;
        }

        const idItem = document.getElementById('idItem').value;
    
        const options = this.getOptions();
        await api.put(`${this.state.url}/${idItem}`, itemSelecionado, options)
        .then(() => {
          this.controlarModal();
          toast.success('Item atualizado');
          this.buscarDados();
        });

      } catch (error) {
        toast.error('Não foi possível editar o item');
      }
    }

    getObjeto() {
      return {
        nome: this.getValueId('nome'),
        whatsapp: this.getValueId('whatsapp'),
        instagram: this.getValueId('instagram'),
        email: this.getValueId('email'),
        origem: this.getValueId('origem'),
        status: this.getValueId('status1'),
        hp: this.getValueId('hp'),
        nivel: this.getValueId('nivel'),
        area: this.getValueId('area')
      };
    }

    getObjetoAcao() {
      return {
        tipo: this.getValueId('tipoAcao'),
        descricao: this.getValueId('descricaoAcao'),
        leads_id: this.getValueId('idLead'),
      };
    }

    getObjetoVenda() {
      const data_venda = this.getValueId('data_venda');
      const idLead = this.getValueId('idLead');
      const nome = this.getValueId('nome');
      const status_venda = this.getValueId('status_venda');
      const parcelas = this.getValueId('parcelas');
      const forma_pagamento = this.getValueId('forma_pagamento');
      const forma_pagamento_entrada = this.getValueId('forma_pagamento_entrada');
      const valor_entrada = this.getValueId('valor_entrada').replace(/[^0-9]/g,'').replace(/(\d{1,2})$/, '.$1');
      const valor_produto = this.getValueId('valor_produto').replace(/[^0-9]/g,'').replace(/(\d{1,2})$/, '.$1');
      const produtos_id = this.getValueId('produtos_id');

      const dadosFormulario = new FormData();
      dadosFormulario.append('data_venda', data_venda);
      dadosFormulario.append('leads_id', idLead);
      dadosFormulario.append('nome', nome);
      dadosFormulario.append('status', status_venda);
      dadosFormulario.append('parcelas', parcelas);
      dadosFormulario.append('forma_pagamento', forma_pagamento);
      dadosFormulario.append('forma_pagamento_entrada', forma_pagamento_entrada);
      dadosFormulario.append('valor_entrada', valor_entrada);
      dadosFormulario.append('valor_produto', valor_produto);
      dadosFormulario.append('produtos_id', produtos_id);

      const comprovante = document.getElementById('comprovante').files[0];
      const comprovante_entrada = document.getElementById('comprovante_entrada').files[0];

      dadosFormulario.append('comprovante', comprovante);
      dadosFormulario.append('comprovante_entrada', comprovante_entrada);

      return dadosFormulario;
    }

    getObjetoEvento() {
      return {
        leads_id: this.getValueId('idLead'),
        status: 'aguardando_evento',
        eventos_id: this.getValueId('eventos_id'),
      };
    }

    async apagarItem(idItem) {
      const options = this.getOptions();
      await api.get(`${this.state.url}/${idItem}`, options)
      .then(({ data })=> {

        this.controlarModalApagar();

        setTimeout(() => {
          this.setValueId('idApagar', idItem);
          this.setValueId('nomeApagar', data.nome);
        }, 500);
      });
    }

    async apagarPermanentemente() {
      const idItem = document.getElementById('idApagar').value;
      const options = this.getOptions();
      await api.delete(`${this.state.url}/${idItem}`, options)
      .then(()=> {

        this.controlarModalApagar();
        toast.success('Item apagado com sucesso');
        this.buscarDados();
      });
    }

    validarCampos(item) {
      const { nome } = item;

      if (!nome) {
        toast.error('Campo nome obrigatório');
        return false;
      }

      return true;
    }

    validarCamposAcao(item) {
      const { tipo, descricao } = item;

      if (!tipo) {
        toast.error('Selecione um tipo de ação');
        return false;
      }

      if (!descricao) {
        toast.error('Informe uma descrição do seu contato');
        return false;
      }

      return true;
    }

    validarCamposEvento(item) {
      const { eventos_id } = item;

      if (!eventos_id) {
        toast.error('Selecione um evento para colocar na lista');
        return false;
      }

      return true;
    }

    validarCamposVenda(item) {

      if (!item.get('produtos_id')) {
        toast.error('Obrigatório escolher um produto.');
        return false;
      }

      if (!item.get('data_venda')) {
        toast.error('Obrigatório informar a data da venda.');
        return false;
      }

      if (!item.get('forma_pagamento')) {
        toast.error('Obrigatório escolher forma de pagamento.');
        return false;
      }

      if (!item.get('parcelas')) {
        toast.error('Obrigatório informar o parcelamento ou a opção à vista.');
        return false;
      }

      if (!item.get('status')) {
        toast.error('Obrigatório informar status da venda.');
        return false;
      }

      

      return true;
    }

    getValueId(nomeId) {
      return document.getElementById(nomeId).value;
    }

    setValueId(nomeId, valor) {
      document.getElementById(nomeId).value = valor;
    }

    controlarModal() {
      this.setState(prevState => ({
        modal_standard: !prevState.modal_standard
      }));
      this.removeBodyCss();
    }

    controlarModalAcao() {
      this.setState(prevState => ({
        modal_acao: !prevState.modal_acao
      }));
      this.removeBodyCss();
    }

    controlarModalEvento() {
      this.setState(prevState => ({
        modal_evento: !prevState.modal_evento
      }));
      this.removeBodyCss();
    }

    controlarModalVenda() {
      this.setState(prevState => ({
        modal_venda: !prevState.modal_venda
      }));
      this.removeBodyCss();
    }

    controlarModalApagar() {
      this.setState(prevState => ({
        modal_apagar: !prevState.modal_apagar
      }));
      this.removeBodyCss();
    }

    removeBodyCss() {
      document.body.classList.add("no_padding");
    }

    componentDidMount() {
      this.buscarDados();
      this.buscarVendasExecutivo();
      this.buscarRankingExecutivo();
    }

    getNivel(nivel) {
      if (nivel === 'frio') {
        return '🥶 Frio';
      }

      if (nivel === 'morno') {
        return '🟡 Morno';
      }

      if (nivel === 'fogo') {
        return '🔥 Quente';
      }
    }

    getIconHistorico(tipo) {
      if (tipo === 'instagram') {
        return 'ri-instagram-fill';
      }

      if (tipo === 'whatsApp') {
        return 'ri-whatsapp-fill';
      }

      if (tipo === 'email') {
        return 'ri-phone-fill';
      }

      if (tipo === 'ligação') {
        return 'ri-mail-fill';
      }
    }

    async cadastrarAcao() {
      try {
        const novoItem = this.getObjetoAcao();
        if (!this.validarCamposAcao(novoItem)) {
          return false;
        }

        const options = this.getOptions();
        await api.post(`leads/acao`, novoItem, options)
        .then(() => {
          toast.success('Ação cadastrado.');
          this.buscarDadosAcao(document.getElementById('idLead').value);
          this.buscarDados();
        });

      } catch (error) {
        toast.error('Não foi possível cadastrar ação.');
      }
    }

    async cadastrarNaLista() {
      try {
        const novoItem = this.getObjetoEvento();
        if (!this.validarCamposEvento(novoItem)) {
          return false;
        }

        const options = this.getOptions();
        await api.post(`/eventos/lead`, novoItem, options)
        .then(() => {
          toast.success('Nome cadastrado na lista.');
          this.buscarDadosEventoLead(document.getElementById('idLead').value);
        });

      } catch (error) {
        toast.error('Não foi possível cadastrar nome na lista.');
      }
    }

    async removerLista(leads_id, eventos_id) {
      try {

        const options = this.getOptions();
        await api.delete(`/eventos/lead/${leads_id}/${eventos_id}`, options)
        .then(() => {
          toast.success('Removido da lista de presença.');
          this.buscarDadosEventoLead(document.getElementById('idLead').value);
        });

      } catch (error) {
        toast.error('Não foi possível cadastrar nome na lista.');
      }
    }

    async cadastrarVenda() {
      try {
        const novoItem = this.getObjetoVenda();
        if (!this.validarCamposVenda(novoItem)) {
          return false;
        }

        const options = this.getOptionsVenda();
        await api.post(`/vendas`, novoItem, options)
        .then(() => {
          toast.success('Venda cadastrada com sucesso.');
          this.controlarModalVenda();
          this.buscarRankingExecutivo();
          this.buscarVendasExecutivo();
        });

      } catch (error) {
        toast.error('Não foi possivel cadastrar venda, fale com o financeiro.');
      }
    }

    boxSelecao = (tipo) => {
      return this.state.filtroCategoria === tipo ? { backgroundColor: '#444444' } : { backgroundColor: '' }
    }

    truncate(str, n){
        if (!str) return '';

        return <>{(str.length > n) ? <>{`${str.slice(0, n-1)}`} &hellip;</> : str}</>
    };

  render() {

    const columns = [{
        dataField: 'ultimo_contato',
        text: 'Último contato',
        sort: true,
        formatter: (cellContent, row) => (
            <React.Fragment>
                <Badge 
                  title={moment(row.updated_at).format('DD/MM/YYYY HH:mm:ss')}
                  color="light"
                  className="bg-light me-1 rounded-pill font-size-18"
                  onClick={() => this.abrirModalAcao(row.id)} 
                  role="button"
                >
                  {row.ultimo_contato ? <>
                    {Math.abs(moment(row.ultimo_contato).diff(new Date(), 'days')) === 0 ? <>
                      Hoje
                    </> : <>
                      {Math.abs(moment(row.ultimo_contato).diff(new Date(), 'days'))} dias atrás
                    </>}
                  </> : <>
                    <b style={{color: '#ff0000'}}>Nenhum feito</b>
                  </>}
                  
                </Badge>
            </React.Fragment>
        )
    }, {
        dataField: 'nome',
        text: 'Nome',
        sort: true,
        formatter: (cellContent, row) => (
          <Badge 
            color="light"
            className="bg-light me-1 rounded-pill font-size-18"
            onClick={() => this.controlarModalEdicao(row.id)} 
            role="button"
          >
            {row.nome}
          </Badge>
      ),
    }, {
      dataField: 'area',
      text: 'Área',
      sort: true,
      formatter: (cellContent, row) => (
        <Badge 
          color="light"
          className="bg-light me-1 rounded-pill font-size-18"
          onClick={() => this.controlarModalEdicao(row.id)} 
          role="button"
          title={row.area}
        >
          {this.truncate(row.area, 10)}
        </Badge>
    ),
    }, {
        dataField: 'whatsapp',
        text: 'Whatsapp',
        sort: true,
        formatter: (cellContent, row) => (
            <React.Fragment>
              {/* {row.whatsapp} &nbsp; */}
              <Link 
                to={{pathname: `https://wa.me/55${row.whatsapp.replace(/[^0-9]/g,'')}`}} 
                className="btn btn-success btn-rounded btn-sm"
                target="_blank"
              >
                  <i className="mdi mdi-whatsapp font-size-18"></i>
              </Link>
              &nbsp;
              <Link 
                to={{pathname: `https://instagram.com/${row.instagram}`}} 
                className="btn btn-danger btn-rounded btn-sm"
                target="_blank"
              >
                  <i className="mdi mdi-instagram font-size-18"></i>
              </Link>
            </React.Fragment>
        ),
    }, {
        dataField: 'status',
        text: 'Status',
        sort: true,
        formatter: (cellContent, row) => (
            <Badge 
              color="light"
              className="bg-light me-1 rounded-pill font-size-18"
              onClick={() => this.controlarModalEdicao(row.id)} 
              role="button"
            >
              {row.status}
            </Badge>
        ),
    }, {
        dataField: 'nivel',
        text: 'Nivel',
        sort: true,
        formatter: (cellContent, row) => (
          <Badge 
            color="light"
            className="bg-light me-1 rounded-pill font-size-18"
            onClick={() => this.controlarModalEdicao(row.id)} 
            role="button"
          >
            {this.getNivel(row.nivel)}
          </Badge>
        ),
    }, {
      dataField: 'origem',
      text: 'Origem',
      sort: true,
      formatter: (cellContent, row) => (
        <Badge 
          color="light"
          className="bg-light me-1 rounded-pill font-size-18"
          onClick={() => this.controlarModalEdicao(row.id)} 
          role="button"
        >
          {row.origem}
        </Badge>
      ),
    }, {
      dataField: 'id',
      isDummyField: true,
      text: "Opções",
      formatter: (cellContent, row) => (
        <React.Fragment>
            <Link 
                to="#" 
                onClick={() => this.abrirModalVenda(row.id)} 
                className="me-1 btn btn-success btn-rounded btn-sm"
                title="Registrar venda"
            >
                <i className="ri-money-dollar-box-line font-size-18"></i>
            </Link>
            <Link 
                to="#" 
                onClick={() => this.abrirModalAcao(row.id)} 
                className="me-1 btn btn-info btn-rounded btn-sm"
                title="Cadastrar ação"
            >
                <i className="mdi mdi-phone-message font-size-18"></i>
            </Link>
            <Link 
                to="#" 
                onClick={() => this.abrirModalEvento(row.id)} 
                className="btn btn-primary btn-rounded btn-sm"
                title="Fazer reserva"
            >
                <i className="mdi mdi-calendar-check font-size-18"></i>
            </Link>
        </React.Fragment>
      ),
    }];

    const defaultSorted = [{
      dataField: 'categorias',
      order: 'asc'
    }];

    const pageOptions = {
      sizePerPage: 10,
      totalSize: this.state.productData.length, // replace later with size(customers),
      custom: true,
    }

    return (
      <React.Fragment>
        <ToastContainer />
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Leads" breadcrumbItems={this.state.breadcrumbItems} />
            <div className="row">
                <div className="col-md-2">
                    <Input id="filtroCategoria" type="text" style={{ display: 'none' }}/>
                    <Link to="#" onClick={() => this.setFiltroCategoria('Fazer Contato')}>
                      <div className="card" style={this.boxSelecao('Fazer Contato')}>
                          <div className="card-body">
                              <div className="d-flex">
                              <div className="flex-1 overflow-hidden">
                                  <h6>Fazer contato</h6>
                                  <h4 className="mb-0">{this.state.fazercontato}</h4>
                              </div>
                              <div className="text-primary"><i className="ri-phone-fill font-size-24"></i></div>
                              </div>
                          </div>
                      </div>
                    </Link>
                </div>
                <div className="col-md-2">
                    <Link to="#" onClick={() => this.setFiltroCategoria('Em contato')}>
                      <div className="card" style={this.boxSelecao('Em contato')}>
                          <div className="card-body">
                              <div className="d-flex">
                              <div className="flex-1 overflow-hidden">
                                  <h6>Em contato</h6>
                                  <h4 className="mb-0">{this.state.emcontato}</h4>
                              </div>
                              <div className="text-primary"><i className="ri-user-voice-fill font-size-24"></i></div>
                              </div>
                          </div>
                      </div>
                    </Link>
                </div>
                <div className="col-md-2">
                  <Link to="#" onClick={() => this.setFiltroCategoria('Sem retorno')}>
                    <div className="card" style={this.boxSelecao('Sem retorno')}>
                          <div className="card-body">
                              <div className="d-flex">
                              <div className="flex-1 overflow-hidden">
                                  <h6>Sem retorno</h6>
                                  <h4 className="mb-0">{this.state.semretorno}</h4>
                              </div>
                              <div className="text-danger"><i className="ri-close-circle-line font-size-24"></i></div>
                              </div>
                          </div>
                      </div>
                  </Link>
                </div>
                <div className="col-md-2">
                  <Link to="#" onClick={() => this.setFiltroCategoria('Agendado Zoom')}>
                    <div className="card" style={this.boxSelecao('Agendado Zoom')}>
                        <div className="card-body">
                            <div className="d-flex">
                            <div className="flex-1 overflow-hidden">
                                <h6>Agendado Zoom</h6>
                                <h4 className="mb-0">{this.state.agendadozoom}</h4>
                            </div>
                            <div className="text-info"><i className="ri-calendar-check-fill font-size-24"></i></div>
                            </div>
                        </div>
                    </div>
                  </Link>
                </div>
                <div className="col-md-2">
                  <Link to="#" onClick={() => this.setFiltroCategoria('Link enviado')}>
                    <div className="card" style={this.boxSelecao('Link enviado')}>
                        <div className="card-body">
                            <div className="d-flex">
                            <div className="flex-1 overflow-hidden">
                                <h6>Link enviado</h6>
                                <h4 className="mb-0">{this.state.linkenviado}</h4>
                            </div>
                            <div className="text-primary"><i className="ri-link-unlink-m font-size-24"></i></div>
                            </div>
                        </div>
                    </div>
                  </Link>
                </div>
                <div className="col-md-2">
                  <Link to="#" onClick={() => this.setFiltroCategoria('Proposta Recusada')}>
                    <div className="card" style={this.boxSelecao('Proposta Recusada')}>
                        <div className="card-body">
                            <div className="d-flex">
                            <div className="flex-1 overflow-hidden">
                                <h6>Proposta Recusada</h6>
                                <h4 className="mb-0">{this.state.propostarecusada}</h4>
                            </div>
                            <div className="text-danger"><i className="ri-shut-down-line font-size-24"></i></div>
                            </div>
                        </div>
                    </div>
                  </Link>
                </div>
                <div className="col-md-2">
                  <Link to="#" onClick={() => this.setFiltroCategoria('Em negociação')}>
                    <div className="card" style={this.boxSelecao('Em negociação')}>
                        <div className="card-body">
                            <div className="d-flex">
                            <div className="flex-1 overflow-hidden">
                                <h6>Em negociação</h6>
                                <h4 className="mb-0">{this.state.emnegociacao}</h4>
                            </div>
                            <div className="text-success"><i className="ri-briefcase-4-line font-size-24"></i></div>
                            </div>
                        </div>
                    </div>
                  </Link>
                </div>
                <div className="col-md-2">
                  <Link to="#" onClick={() => this.setFiltroCategoria('Venda extraordinária')}>
                    <div className="card" style={this.boxSelecao('Venda extraordinária')}>
                        <div className="card-body">
                            <div className="d-flex">
                            <div className="flex-1 overflow-hidden">
                                <h6>Venda extraordinária</h6>
                                <h4 className="mb-0">{this.state.vendaextraordinaria}</h4>
                            </div>
                            <div className="text-success"><i className="ri-coins-fill font-size-24"></i></div>
                            </div>
                        </div>
                    </div>
                  </Link>
                </div>
                <div className="col-md-2">
                  <Link to="#" onClick={() => this.setFiltroCategoria('Venda em aberto')}>
                    <div className="card" style={this.boxSelecao('Venda em aberto')}>
                        <div className="card-body">
                            <div className="d-flex">
                            <div className="flex-1 overflow-hidden">
                                <h6>Venda em aberto</h6>
                                <h4 className="mb-0">{this.state.vendaemaberto}</h4>
                            </div>
                            <div className="text-warning"><i className="ri-coins-fill font-size-24"></i></div>
                            </div>
                        </div>
                    </div>
                  </Link>
                </div>
                <div className="col-md-2">
                  <Link to="/rumo">
                    <div className="card" title="A comissão aparece depois que a venda for aprovada pelo financeiro.">
                        <div className="card-body">
                            <div className="d-flex">
                            <div className="flex-1 overflow-hidden">
                                <h6>COMISSÃO</h6>
                                <h5 className="mb-0">R$ {this.state.comissao}</h5>
                            </div>
                            <div className="text-warning"><i className="ri-medal-line font-size-24"></i></div>
                            </div>
                        </div>
                    </div>
                  </Link>
                </div>
                <div className="col-md-2">
                  <Link to="/ranking">
                    <div className="card" title="A comissão aparece depois que a venda for aprovada pelo financeiro.">
                        <div className="card-body">
                            <div className="d-flex">
                            <div className="flex-1 overflow-hidden">
                                <h6>RANKING</h6>
                                <h5 className="mb-0">Ver detalhes</h5>
                            </div>
                            <div className="text-warning font-size-24">🏆</div>
                            </div>
                        </div>
                    </div>
                  </Link>
                </div>
                <div className="col-md-2">
                  <Link to="/vendas">
                    <div className="card" title="A comissão aparece depois que a venda for aprovada pelo financeiro.">
                        <div className="card-body">
                            <div className="d-flex">
                            <div className="flex-1 overflow-hidden">
                                <h6>VENDAS</h6>
                                <h5 className="mb-0">Ver detalhes</h5>
                            </div>
                            <div className="text-warning font-size-24">💰</div>
                            </div>
                        </div>
                    </div>
                  </Link>
                </div>
            </div>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='id'
                      columns={columns}
                      data={this.state.productData}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='id'
                          columns={columns}
                          data={this.state.productData}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>

                              <Row className="mb-2">
                                <Col md="8">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <Row className="">
                                          <Col md={3}>
                                            <select 
                                              id="filtroContatos" 
                                              className="form-control font-size-18" 
                                              title="Filtro utilizado na coluna Últimos contatos"
                                              onChange={() => this.pesquisar()}
                                              style={{ paddingLeft: '15px' }}
                                            >
                                                <option value="">Todos os contatos</option>
                                                <option value="1">Hoje</option>
                                                <option value="3">3 dias ou menos</option>
                                                <option value="7">7 dias ou menos</option>
                                                <option value="15">15 dias ou menos</option>
                                                <option value="30">30 dias ou menos</option>
                                                <option value="31">30 dias ou MAIS</option>
                                                <option value="null">Nenhum feito</option>
                                            </select>
                                          </Col>
                                          <Col md={3}>
                                              <Input 
                                                id="valorPesquisar" 
                                                type="text" 
                                                className="font-size-18" 
                                                placeholder="Pesquisar" 
                                                onChange={() => this.pesquisar()}
                                                style={{ paddingLeft: '15px' }}
                                              />
                                          </Col>
                                          <Col md={3}>
                                              <select 
                                                id="filtroNiveis" 
                                                className="form-control font-size-18"
                                                onChange={() => this.pesquisar()}
                                                style={{ paddingLeft: '15px' }}
                                              >
                                                  <option value="">Todos os níveis</option>
                                                  <option value="frio">🥶 Frio</option>
                                                  <option value="morno">🟡 Morno</option>
                                                  <option value="fogo">🔥 Quente</option>
                                              </select>
                                          </Col>

                                          <Col md={3}>
                                            <select 
                                              id="filtroOrigem" 
                                              className="form-control font-size-18"
                                              style={{ paddingLeft: '15px' }}
                                              onChange={() => this.pesquisar()}
                                            >
                                                <option value="">Todas as origens</option>
                                                <option value="minha-agenda">Minha Agenda</option>
                                                <option value="stories">Stories</option>
                                                <option value="direct">Direct</option>
                                                <option value="live">Live</option>
                                                <option value="trafegopago">Tráfego Pago</option>
                                                <option value="indicacao-hp">Inidicação HP</option>
                                                <option value="indicacao-m7d">Inidicação M7D</option>
                                                <option value="provi">Provi</option>
                                            </select>
                                          </Col>


                                      </Row>
                                      <i className="search-box chat-search-box" />
                                    </div>
                                  </div>
                                </Col>
                                <Col sm="4">
                                  <div className="text-sm-end">
                                    <Button
                                      type="button"
                                      color="primary"
                                      className="btn-rounded mb-2 me-2"
                                      onClick={this.controlarModal}
                                    >
                                      <i className="mdi mdi-account-plus font-size-18"></i> <span className="font-size-18">Cadastrar Lead</span>
                                    </Button>
                                    {/* <Button
                                      type="button"
                                      color="success"
                                      className="btn-rounded mb-2 me-2"
                                      onClick={this.controlarModal}
                                    >
                                      <i className="mdi mdi-grid font-size-18"></i> <span className="font-size-18">Importar Leads</span>
                                    </Button>
                                    <Button
                                      type="button"
                                      color="info"
                                      className="btn-rounded mb-2 me-2"
                                      onClick={this.controlarModal}
                                    >
                                      <i className="mdi mdi-link-variant font-size-18"></i> <span className="font-size-18">Link de Indicação</span>
                                    </Button> */}
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField='idusuarios'
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      ref={(n) => (this.node = n)}
                                      classes={
                                        "table align-middle table-nowrap table-hover"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />

                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
        <Modal
          isOpen={this.state.modal_standard}
          toggle={this.controlarModal}
        >
          <ModalHeader toggle={() => this.setState({ modal_standard: false })}>
              Cadastro
          </ModalHeader>
          <ModalBody>
            <Row className="mb-3">
                <Label className="form-label">Nome</Label>
                <Col md={12}>
                    <Input id="idItem" type="text" style={{ display: 'none' }}/>
                    <Input id="nome" type="text"/>
                </Col>  
            </Row>
            <Row className="mb-3">
                <Label className="form-label">WhatsApp</Label>
                <Col md={12}>
                    <Form.Control
                      as={InputMask}
                      mask="(99) 9 9999-9999"
                      placeholder="Digite o whatsapp"
                      id="whatsapp"
                    />
                </Col>  
            </Row>
            <Row className="mb-3">
                <Label className="form-label">Área de atuação</Label>
                <Col md={12}>
                    <Input id="area" type="text"/>
                </Col>  
            </Row>
            
            <Row className="mb-3">
                <Label className="form-label">Status</Label>
                <Col md={12}>
                    <select id="status1" className="form-control">
                        <option value="Fazer Contato" selected>01 - Fazer Contato</option>
                        <option value="Em contato">02 - Em contato</option>
                        <option value="Agendado Zoom">03 - Agendado Zoom</option>
                        <option value="Link enviado">04 - Link enviado</option>
                        <option value="Em negociação">05 - Em negociação</option>
                        <option value="Proposta Recusada">06 - Proposta Recusada</option>
                        <option value="Venda Extraordinária">07 - Venda Extraordinária</option>
                        <option value="Venda em aberto">08 - Venda em aberto</option>
                        <option value="Sem retorno">09 - Sem retorno</option>
                        <option value="N. não existe">10 - N. não existe</option>
                    </select>
                </Col>
            </Row>

            <Row className="mb-3">
                <Label className="form-label">Nivel</Label>
                <Col md={12}>
                    <select id="nivel" className="form-control">
                        <option value="frio" selected>🥶 Frio</option>
                        <option value="morno">🟡 Morno</option>
                        <option value="fogo">🔥 Quente</option>
                    </select>
                </Col>
            </Row>
            
            <Row className="mb-3">
                <Label className="form-label">Origem</Label>
                <Col md={12}>
                    <select id="origem" className="form-control">
                        <option value="minha-agenda" selected>Minha Agenda</option>
                        <option value="stories">Stories</option>
                        <option value="direct">Direct</option>
                        <option value="live">Live</option>
                        <option value="trafegopago">Tráfego Pago</option>
                        <option value="indicacao-hp">Inidicação HP</option>
                        <option value="indicacao-m7d">Inidicação M7D</option>
                        <option value="provi">Provi</option>
                    </select>
                </Col>
            </Row>
            <Row className="mb-3">
                <Label className="form-label">Fez HP?</Label>
                <Col md={12}>
                    <select id="hp" className="form-control">
                        <option value="nao" selected>Não</option>
                        <option value="sim">Sim</option>
                    </select>
                </Col>
            </Row>
            <Row className="mb-3">
                <Label className="form-label">Instagram</Label>
                <Col md={12}>
                    <Input id="instagram" type="text"/>
                </Col>  
            </Row>
            <Row className="mb-3">
                <Label className="form-label">Email</Label>
                <Col md={12}>
                    <Input id="email" type="text"/>
                </Col>  
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              onClick={this.controlarModal}
              color="light"
              className="waves-effect"
            >
              Fechar
          </Button>
            <Button
              type="button"
              color="primary" className="waves-effect waves-light"
              onClick={() => this.cadastrarOuEditar()}
            >
              Salvar
          </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.modal_apagar}
          toggle={this.controlarModalApagar}
        >
          <ModalHeader toggle={() => this.setState({ modal_apagar: false })}>
              Telefone não existe
          </ModalHeader>
          <ModalBody>
            <Input id="idApagar" type="text" style={{ display: 'none' }}/>
            <p id="nomeApagar">Você tentou entrar em contato e o telefone não existe?</p>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              onClick={this.controlarModalApagar}
              color="light"
              className="waves-effect"
            >
              Voltar
          </Button>
            <Button
              type="button"
              color="danger" className="waves-effect waves-light"
              onClick={() => this.apagarPermanentemente()}
            >
              Telefone não existe
          </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.modal_acao}
          toggle={this.controlarModalAcao}
        >
        <ModalHeader toggle={() => this.setState({ modal_acao: false })}>
              Registrar ação de contato
          </ModalHeader>
          <ModalBody>
            <Row className="mb-3">
                <Label className="form-label">Lead / Cliente</Label>
                <Col md={12}>
                    <Input id="idLead" type="text" style={{ display: 'none' }}/>
                    <Input id="nome" type="text" disabled/>
                </Col>  
            </Row>
            
            <Row className="mb-3">
                <Label className="form-label">Tipo do contato</Label>
                <Col md={12}>
                    <select id="tipoAcao" className="form-control">
                        <option value="">Selecionar</option>
                        <option value="whatsApp">01 - WhatsApp</option>
                        <option value="instagram">02 - Instagram</option>
                        <option value="ligação">03 - Ligação</option>
                        <option value="email">04 - Email</option>
                    </select>
                </Col>
            </Row>
            <Row className="mb-3">
                <Label className="form-label">Descrição do contato</Label>
                <Col md={12}>
                    <Input id="descricaoAcao" type="textarea"/>
                </Col>
            </Row>
            <Row className="d-grid mb-3">
                <Button
                  color="primary"
                  className="btn btn-primary btn-block"
                  onClick={() => this.cadastrarAcao()}
                >
                  Salvar contato
                </Button>
            </Row>
            
            <Row className="mb-3">
                <Label className="form-label">Histórico de contatos</Label>
                <ul className="list-unstyled activity-wid" style={{ height: '200px', overflow: 'hidden scroll' }}>

                  {this.state.acaoData.map((acao) => {
                    return (
                        <li className="activity-list">
                            <div className="activity-icon avatar-xs">
                              <span className="avatar-title bg-soft-primary text-primary rounded-circle">
                                <i className={this.getIconHistorico(acao.tipo)}></i>
                              </span>
                            </div>
                            <div>
                              <div>
                                  <h5 className="font-size-13">
                                    {moment(acao.created_at).format('DD/MM/YYYY')} &nbsp;
                                    <small className="text-muted">{moment(acao.created_at).format('HH:mm:ss')}</small>
                                  </h5>
                              </div>
                              <div>
                                  <p className="text-muted mb-0">{acao.descricao}</p>
                              </div>
                            </div>
                        </li>
                    )
                  })}
                </ul>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              onClick={this.controlarModalAcao}
              color="light"
              className="waves-effect"
            >
                Fechar
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.modal_evento}
          toggle={this.controlarModalEvento}
        >
          <ModalHeader toggle={() => this.setState({ modal_evento: false })}>
              Efetuar reserva para evento
          </ModalHeader>
          <ModalBody>
            <Row className="mb-3">
                <Label className="form-label">Lead / Cliente</Label>
                <Col md={12}>
                    <Input id="idLead" type="text" style={{ display: 'none' }}/>
                    <Input id="nome" type="text" disabled/>
                </Col>  
            </Row>
            
            <Row className="mb-3">
                <Label className="form-label">Evento</Label>
                <Col md={12}>
                    <select id="eventos_id" className="form-control">
                        <option value="">Selecionar</option>
                        {this.state.eventoData.map((evento) => {
                          return (
                            <option value={evento.id}>
                              {moment(evento.inicio).format('DD/MM')} - {evento.nome}
                            </option>
                          )
                        })}
                    </select>
                </Col>
            </Row>
            <Row className="d-grid mb-3">
                <Button
                  color="primary"
                  className="btn btn-primary btn-block"
                  onClick={() => this.cadastrarNaLista()}
                >
                  Cadastrar na lista de presença
                </Button>
            </Row>
            <Row className="mb-3">
                <Label className="form-label">Histórico de contatos</Label>
                <ul className="list-unstyled activity-wid" style={{ height: '200px', overflow: 'hidden scroll' }}>

                  {this.state.eventoLeadData.map((lead) => {
                    return (
                        <li className="activity-list">
                            <div className="activity-icon avatar-xs">
                              <span className="avatar-title bg-soft-primary text-success rounded-circle">
                                <i className="mdi mdi-calendar-clock"></i>
                              </span>
                            </div>
                            <div>
                              <div>
                                  <h5 className="font-size-13">
                                    {moment(lead.inicio).format('DD/MM/YYYY')} &nbsp;
                                    <small className="text-muted">{lead.status}</small> &nbsp;
                                    <Button
                                      color="danger"
                                      size="sm"
                                      className="waves-effect"
                                      onClick={() => this.removerLista(lead.leads_id, lead.eventos_id)}
                                    >
                                      Remover da lista
                                    </Button>
                                  </h5>
                              </div>
                              <div>
                                  <p className="text-muted mb-0">{lead.nome}</p>
                              </div>
                            </div>
                        </li>
                    )
                  })}
                </ul>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              onClick={this.controlarModalEvento}
              color="light"
              className="waves-effect"
            >
                Fechar
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.modal_venda}
          toggle={this.controlarModalVenda}
        >
          <ModalHeader toggle={() => this.setState({ modal_venda: false })}>
              Registrar Venda Extraordinária
          </ModalHeader>
          <ModalBody>
            <Row className="mb-3">
                <Label className="form-label">Cliente</Label>
                <Col md={12}>
                    <Input id="idLead" type="text" style={{ display: 'none' }}/>
                    <Input id="nome" type="text" disabled/>
                </Col>  
            </Row>
            <Row className="mb-3">
                <Label className="form-label">Data da venda</Label>
                <Col md={12}>
                    <Input id="data_venda" type="date"/>
                </Col>  
            </Row>
            <Row className="mb-3">
                <Label className="form-label">Qual produto foi vendido?</Label>
                <Col md={12}>
                    <select id="produtos_id" className="form-control">
                        <option value="">Selecionar</option>
                        {this.state.produtosData.map((produto) => {
                          return (
                            <option value={produto.id}>
                              {produto.nome}
                            </option>
                          )
                        })}
                    </select>
                </Col>
            </Row>
            
            <Row className="mb-3">
                <Label className="form-label">R$ Valor pago de entrada</Label>
                <Col md={12}>
                    <InputDinheiro id="valor_entrada" />
                </Col>  
            </Row>
            <Row className="mb-3">
                <Label className="form-label">Forma de Pagamento da entrada</Label>
                <Col md={12}>
                    <select id="forma_pagamento_entrada" className="form-control">
                        <option value="">Selecionar...</option>
                        <option value="pix">Pix</option>
                        <option value="pix-empresa">Pix Empresa</option>
                        <option value="pix-eduzz">Pix Eduzz</option>
                        <option value="edduz">Edduz</option>
                        <option value="maquina_cartao">Maquina de Cartão</option>
                        <option value="provi">Provi</option>
                        <option value="dinheiro">Dinheiro</option>
                    </select>
                </Col>
            </Row>

            <Row className="mb-3">
                <Label className="form-label">Anexar comprovante da entrada</Label>
                <Col md={12}>
                  <div className="input-group">
                      <input type="file" className="form-control" id="comprovante_entrada" />
                  </div>
                </Col>
            </Row>

            <Row className="mb-3">
                <Label className="form-label">R$ Valor do Produto</Label>
                <Col md={12}>
                    <InputDinheiro id="valor_produto" />
                </Col>  
            </Row>

            <Row className="mb-3">
                <Label className="form-label">Forma de Pagamento do valor restante</Label>
                <Col md={12}>
                    <select id="forma_pagamento" className="form-control">
                        <option value="">Selecionar...</option>
                        <option value="pix">Pix</option>
                        <option value="pix-empresa">Pix Empresa</option>
                        <option value="pix-eduzz">Pix Eduzz</option>
                        <option value="edduz">Edduz</option>
                        <option value="maquina_cartao">Maquina de Cartão</option>
                        <option value="provi">Provi</option>
                        <option value="dinheiro">Dinheiro</option>
                    </select>
                </Col>
            </Row>

            <Row className="mb-3">
                <Label className="form-label">Anexar comprovante do valor restante</Label>
                <Col md={12}>
                  <div className="input-group">
                      <input type="file" className="form-control" id="comprovante" />
                  </div>
                </Col>
            </Row>

            <Row className="mb-3">
                <Label className="form-label">Parcelamento</Label>
                <Col md={12}>
                    <select id="parcelas" className="form-control">
                        <option value="">Selecionar...</option>
                        <option value="0">À vista</option>
                        <option value="1">1x</option>
                        <option value="2">2x</option>
                        <option value="3">3x</option>
                        <option value="4">4x</option>
                        <option value="5">5x</option>
                        <option value="6">6x</option>
                        <option value="7">7x</option>
                        <option value="8">8x</option>
                        <option value="9">9x</option>
                        <option value="10">10x</option>
                        <option value="11">11x</option>
                        <option value="12">12x</option>
                        <option value="13">13x</option>
                        <option value="14">14x</option>
                        <option value="15">15x</option>
                        <option value="16">16x</option>
                        <option value="17">17x</option>
                        <option value="18">18x</option>
                        <option value="24">24x</option>
                        <option value="36">36x</option>
                    </select>
                </Col>
            </Row>

            <Row className="mb-3">
                <Label className="form-label">Status da venda</Label>
                <Col md={12}>
                    <select id="status_venda" className="form-control">
                        <option value="">Selecionar...</option>
                        <option value="pagamento_total">Pagamento Total</option>
                        <option value="pagamento_parcial">Pagamento Parcial</option>
                    </select>
                </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              onClick={this.controlarModalVenda}
              color="light"
              className="waves-effect"
            >
                Fechar
            </Button>
            <Button
              color="success"
              className="btn btn-primary btn-block"
              onClick={() => this.cadastrarVenda()}
            >
              Cadastrar venda
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    )
  }
}

export default Leads;