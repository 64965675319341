import React, { Component } from "react";
import moment from 'moment';

import { DateRangePicker, Stack, CustomProvider } from 'rsuite';
import { 
  isSunday,
  isMonday,
  nextSunday,
  previousMonday,
  endOfMonth,
  startOfMonth
}  from 'date-fns';

import { Row, Col, Card, CardBody } from "reactstrap";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import { ToastContainer } from 'react-toastify';

import ToolkitProvider from 'react-bootstrap-table2-toolkit';

import api from '../../../services/api';

import Breadcrumbs from "../../../components/Common/Breadcrumb";
import PredefinedRanges from '../../../components/PredefinedRanges';
import "../../../assets/scss/datatables.scss";

class Ranking extends Component {
    constructor(props) {
      super(props)
      this.state = {
        breadcrumbItems: [
          { title: "Menu", link: "/dashboard" }
        ],
        page: 1,
        sizePerPage: 50,
        productData: [],
        anoData: [],
        semanaData: [],
        diaData: [],
        filtro: '',
        url: '/executivo/ranking',
        ranking: '',
        link: process.env.NODE_ENV === 'development'? 'http://localhost:3003' : 'https://api.venda.show',
        mesAtual: [startOfMonth(new Date()), endOfMonth(new Date())],
        anoAtual: [new Date(new Date().getFullYear(), 0, 1), new Date(new Date().getFullYear(), 11, 31)],
        diaAtual: [new Date(), new Date()],
        semanaAtual: [
          isMonday(new Date()) ? new Date() : previousMonday(new Date()), 
          isSunday(new Date()) ? new Date() : nextSunday(new Date())
        ]
      }
    };

    async buscarDados() {
      const options = this.getOptions();

      let filtro = [];
  
      const range = this.state.mesAtual;
      if (range) {
        filtro = range;
      }

      const inicio = this.formatar(filtro[0]);
      const fim = this.formatar(filtro[1]);

      const { data } = await api.get(`${this.state.url}?inicio=${inicio}&fim=${fim}`, options);

      this.setState({
        productData: data
      });
    }

    async buscarDadosAno() {
      const options = this.getOptions();

      let filtro = [];
  
      const range = this.state.anoAtual;
      if (range) {
        filtro = range;
      }

      const inicio = this.formatar(filtro[0]);
      const fim = this.formatar(filtro[1]);

      const { data } = await api.get(`${this.state.url}?inicio=${inicio}&fim=${fim}`, options);

      this.setState({
        anoData: data
      });
    }

    async buscarDadosSemana() {
      const options = this.getOptions();

      let filtro = [];
  
      const range = this.state.semanaAtual;
      if (range) {
        filtro = range;
      }

      const inicio = this.formatar(filtro[0]);
      const fim = this.formatar(filtro[1]);

      const { data } = await api.get(`${this.state.url}?inicio=${inicio}&fim=${fim}`, options);

      this.setState({
        semanaData: data
      });
    }

    async buscarDadosDia() {
      const options = this.getOptions();

      let filtro = [];
  
      const range = this.state.diaAtual;
      if (range) {
        filtro = range;
      }

      const inicio = this.formatar(filtro[0]);
      const fim = this.formatar(filtro[1]);

      const { data } = await api.get(`${this.state.url}?inicio=${inicio}&fim=${fim}`, options);

      this.setState({
        diaData: data
      });
    }

    formatar(data) {
      return moment(data).format('YYYY/MM/DD');
    }

    getOptions() {
        let obj = '';
        if (localStorage.getItem("authUser")) {
        obj = JSON.parse(localStorage.getItem("authUser"));
        }

        const options = {
        headers: {"Authorization" : `Bearer ${obj.token}`}
        }

        return options;
    }

    componentDidMount() {
      this.buscarDados();
      this.buscarDadosAno();
      this.buscarDadosDia();
      this.buscarDadosSemana();
    }

  render() {

    const columns = [
      {
        dataField: 'dens_rank',
        text: '🏆',
        sort: true,
        formatter: (cellContent, row) => (
            <React.Fragment>
                {row.dens_rank}°
            </React.Fragment>
          )
    },{
        dataField: 'executivo',
        text: 'Executivo',
        sort: true
    }];

    const defaultSorted = [{
      dataField: 'dens_rank',
      order: 'asc'
    }];

    const pageOptions = {
      sizePerPage: 50,
      totalSize: this.state.productData.length,
      custom: true,
    }

    return (
      <React.Fragment>
        <ToastContainer />
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Ranking de Vendas" breadcrumbItems={this.state.breadcrumbItems} />
            <Row>
              <Col className="col-md-4">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='id'
                      columns={columns}
                      data={this.state.anoData}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='id'
                          columns={columns}
                          data={this.state.anoData}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row>
                                <Col sm="12">
                                  <h3 className="text-center">ANO - Viagem ou Carro</h3>
                                  <div className="d-flex justify-content-center">
                                    <CustomProvider theme='dark'>
                                      <Stack direction="column" spacing={8} alignItems="flex-start">
                                        <DateRangePicker
                                          ranges={PredefinedRanges}
                                          placeholder="Selecionar data"
                                          style={{ width: 300 }}
                                          id="calendario"
                                          value={this.state.anoAtual}
                                          onChange={ 
                                            event => {
                                              setTimeout(() => {
                                                this.setState({ anoAtual : event });
                                                this.buscarDadosAno();
                                              }, 0);
                                            }
                                          }
                                        />
                                      </Stack>
                                    </CustomProvider>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField='idusuarios'
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      ref={(n) => (this.node = n)}
                                      classes={
                                        "table align-middle table-nowrap table-hover"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />

                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
              <Col className="col-md-4">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='id'
                      columns={columns}
                      data={this.state.productData}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='id'
                          columns={columns}
                          data={this.state.productData}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row>
                                <Col sm="12">
                                  <h3 className="text-center">MÊS - IPHONE</h3>
                                  <div className="d-flex justify-content-center">
                                    <CustomProvider theme='dark'>
                                      <Stack direction="column" spacing={8} alignItems="flex-start">
                                        <DateRangePicker
                                          ranges={PredefinedRanges}
                                          placeholder="Selecionar data"
                                          style={{ width: 300 }}
                                          id="calendario"
                                          value={this.state.mesAtual}
                                          onChange={ 
                                            event => {
                                              setTimeout(() => {
                                                this.setState({ mesAtual : event });
                                                this.buscarDados();
                                              }, 0);
                                            }
                                          }
                                        />
                                      </Stack>
                                    </CustomProvider>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField='idusuarios'
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      ref={(n) => (this.node = n)}
                                      classes={
                                        "table align-middle table-nowrap table-hover"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />

                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
              <Col className="col-md-4">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='id'
                      columns={columns}
                      data={this.state.semanaData}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='id'
                          columns={columns}
                          data={this.state.semanaData}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row>
                                <Col sm="12">
                                  <h3 className="text-center">SEMANA - R$ 500,00</h3>
                                  <div className="d-flex justify-content-center">
                                    <CustomProvider theme='dark'>
                                      <Stack direction="column" spacing={8} alignItems="flex-start">
                                        <DateRangePicker
                                          ranges={PredefinedRanges}
                                          placeholder="Selecionar data"
                                          style={{ width: 300 }}
                                          id="calendario"
                                          value={this.state.semanaAtual}
                                          onChange={ 
                                            event => {
                                              setTimeout(() => {
                                                this.setState({ semanaAtual : event });
                                                this.buscarDadosSemana();
                                              }, 0);
                                            }
                                          }
                                        />
                                      </Stack>
                                    </CustomProvider>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField='idusuarios'
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      ref={(n) => (this.node = n)}
                                      classes={
                                        "table align-middle table-nowrap table-hover"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />

                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
              <Col className="col-md-4">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='id'
                      columns={columns}
                      data={this.state.diaData}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='id'
                          columns={columns}
                          data={this.state.diaData}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row>
                                <Col sm="12">
                                  <h3 className="text-center">DIA - R$ 50,00</h3>
                                  <div className="d-flex justify-content-center">
                                    <CustomProvider theme='dark'>
                                      <Stack direction="column" spacing={8} alignItems="flex-start">
                                        <DateRangePicker
                                          ranges={PredefinedRanges}
                                          placeholder="Selecionar data"
                                          style={{ width: 300 }}
                                          id="calendario"
                                          value={this.state.diaAtual}
                                          onChange={ 
                                            event => {
                                              setTimeout(() => {
                                                this.setState({ diaAtual : event });
                                                this.buscarDadosDia();
                                              }, 0);
                                            }
                                          }
                                        />
                                      </Stack>
                                    </CustomProvider>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField='idusuarios'
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      ref={(n) => (this.node = n)}
                                      classes={
                                        "table align-middle table-nowrap table-hover"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />

                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default Ranking;