import React, { Component } from "react";
import moment from 'moment';

import { Stack, CustomProvider } from 'rsuite';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';

import { Row, Col, Card, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Progress } from "reactstrap";
import { Link } from "react-router-dom";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import { ToastContainer, toast } from 'react-toastify';

import ToolkitProvider from 'react-bootstrap-table2-toolkit';

import api from '../../../services/api';

import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "../../../assets/scss/datatables.scss";

class Horas extends Component {
    constructor(props) {
      super(props)
      this.state = {
        breadcrumbItems: [
          { title: "Menu", link: "/dashboard" },
          { title: "Horas", link: "#" },
        ],
        page: 1,
        sizePerPage: 10,
        productData: [],
        acaoData: [],
        eventoData: [],
        eventoLeadData: [],
        produtosData: [],
        visible: false,
        modal_cadastro: false,
        modal_apagar: false,
        modal_acao: false,
        modal_evento: false,
        filtro: '',
        url: '/horas',
        total_iniciar: '0',
        total_andamento: '0',
        total_concluido: '0',
        total_pausado: '0',
        total_geral: '0',
        filtroCategoria: '',
        mesAtual: [startOfMonth(new Date()), endOfMonth(new Date())],
      }

      this.controlarModal = this.controlarModal.bind(this);
    };

    setTotal(data) {
      console.log(data);
      this.setState({
        total_iniciar: data.iniciar
      });
      this.setState({
        total_andamento: data.andamento
      });
      this.setState({
        total_concluido: data.concluido
      });
      this.setState({
        total_pausado: data.pausado
      });
    }

    formatarValor(valor) {
      return valor ? parseFloat(valor).toLocaleString('pt-br', {minimumFractionDigits: 2}): '0,00';
    }

    setZerarTotal() {
      this.setState({
        total_comissoes: '0',
        total_pago: '0',
        total_pendente: '0',
      })
    }

    pesquisar() {
      const valorPesquisa = this.getValueId('valorPesquisar');
      
      if (document.getElementsByClassName("page-link").length > 0) {
        document.getElementsByClassName("page-link")[0].click();
      }
      
      this.setState({
        filtro: valorPesquisa
      });

      this.buscarDados();
    }

    async buscarDados() {
      const options = this.getOptions();

      let pesquisa = '';
      if (this.getValueId('valorPesquisar')) {
        pesquisa = this.getValueId('valorPesquisar');
      }

      let filtroCategoria = '';
      if (this.getValueId('filtroCategoria')) {
        filtroCategoria = this.getValueId('filtroCategoria');
      }

      let filtro = [];
  
      const range = this.state.mesAtual;
      if (range) {
        filtro = range;
      }

      const inicio = this.formatar(filtro[0]);
      const fim = this.formatar(filtro[1]);

      const { data } = await api.get(`${this.state.url}?filtro=${pesquisa}&filtroCategoria=${filtroCategoria}&inicio=${inicio}&fim=${fim}`, options);

      this.setState({
        productData: data,
        total_geral: data.length
      });

      this.buscarTotal();
    }

    setFiltroCategoria(categoria) {
      this.setValueId('filtroCategoria', categoria);

      if (categoria === this.state.filtroCategoria) {
        this.setState({
          filtroCategoria: ''
        });
        this.setValueId('filtroCategoria', '');
      } else {
        this.setState({
          filtroCategoria: categoria
        });
      }

      this.buscarDados();
    }

    async buscarTotal() {
      try {
        const options = this.getOptions();

        let filtro = [];
    
        const range = this.state.mesAtual;
        if (range) {
          filtro = range;
        }
  
        const inicio = this.formatar(filtro[0]);
        const fim = this.formatar(filtro[1]);
  
        const { data } = await api.get(`/horas/total?inicio=${inicio}&fim=${fim}`, options);
  
        //this.setZerarTotal();
        this.setTotal(data)
      } catch (error) {
        
      }
    }

    formatar(data) {
      return moment(data).format('DD/MM/YYYY');
    }

    getOptions() {
        let obj = '';
        if (localStorage.getItem("authUser")) {
        obj = JSON.parse(localStorage.getItem("authUser"));
        }

        const options = {
        headers: {"Authorization" : `Bearer ${obj.token}`}
        }

        return options;
    }

    cadastrarOuEditar() {
      const existeId = document.getElementById('idCadastro').value;

      if (existeId) {
        this.atualizacao();
      } else {
        this.cadastro();
      }
    }

    async abrirModalEdicao(idItem) {
      const options = this.getOptions();
      await api.get(`${this.state.url}/${idItem}`, options)
      .then(({ data })=> {

        this.controlarModal();

        setTimeout(() => {
            this.setValueId('idCadastro', idItem);
            this.setValueId('categoria', data.categoria);
            this.setValueId('atividade', data.atividade);
            this.setValueId('horas', data.horas);
        }, 500);
      });
    }

    getValueId(nomeId) {
      return document.getElementById(nomeId).value;
    }

    setValueId(nomeId, valor) {
      document.getElementById(nomeId).value = valor;
    }

    controlarModal() {
      this.setState(prevState => ({
        modal_cadastro: !prevState.modal_cadastro
      }));
      this.removeBodyCss();
    }

    removeBodyCss() {
      document.body.classList.add("no_padding");
    }

    componentDidMount() {
      this.buscarDados();
    }

    async cadastro() {
      try {
        const novoItem = this.getObjeto();
        if (!this.validarCampos(novoItem)) {
          return false;
        }
        const options = this.getOptions();
        await api.post(this.state.url, novoItem, options)
        .then(() => {
          this.controlarModal();
          toast.success('O cadastro foi um sucesso.');
          this.buscarDados();
        });

      } catch (error) {
        toast.error('Não foi possível fazer o cadastro. Tente novamente mais tarde.');
      }
    }

    getObjeto() {
      return {
        categoria: this.getValueId('categoria'),
        atividade: this.getValueId('atividade'),
        horas: this.getValueId('horas')
      };
    }

    validarCampos(item) {
      const { categoria, atividade, horas } = item;

      if (!categoria) {
        toast.error('Campo categoria obrigatório');
        return false;
      }

      if (!atividade) {
        toast.error('Campo atividade obrigatório');
        return false;
      }

      if (!horas) {
        toast.error('Campo horas obrigatório');
        return false;
      }

      return true;
    }

    async atualizacao() {
      try {
        const novoItem = this.getObjeto();
        if (!this.validarCampos(novoItem)) {
          return false;
        }

        const idCadastro = this.getValueId('idCadastro');

        const options = this.getOptions();
        await api.put(`/horas/${idCadastro}`, novoItem, options)
        .then(() => {
          toast.success('Atualização feita com sucesso!');
          this.controlarModal();
          this.buscarDados();
        });

      } catch (error) {
        toast.error('Não foi possivel atualizar, tente novamente mais tarde.');
      }
    }

    boxSelecao = (tipo) => {
      return this.state.filtroCategoria === tipo ? { backgroundColor: '#444444' } : { backgroundColor: '' }
    }

  render() {

    const columns = [
    {
      dataField: 'id',
      text: 'ID',
      sort: true
    }, {
      dataField: 'categoria',
      text: 'Categoria',
      sort: true
    }, {
      dataField: 'atividade',
      text: 'Atividade',
      sort: true
    }, {
      dataField: 'horas',
      text: 'Horas do seu dia',
      sort: true,
      formatter: (cellContent, row) => (
          <React.Fragment>
              {row.horas ? <>
                <div className="badge badge-soft-success font-size-24">
                  <b>{row.horas}</b>
                </div>
              </> : <>
                <div className="badge badge-soft-success font-size-12">
                  <b>{row.horas}</b>
                </div>
              </>}
          </React.Fragment>
      )
    }, {
      dataField: 'horas',
      text: 'Horas mês',
      sort: true,
      formatter: (cellContent, row) => (
          <React.Fragment>
              {row.horas ? <>
                <div className="badge badge-soft-warning font-size-24">
                  <b>{row.horas * 30}</b>
                </div>
              </> : <>
                <div className="badge badge-soft-warning font-size-12">
                  <b>{row.horas * 30}</b>
                </div>
              </>}
          </React.Fragment>
      )
    }, {
      dataField: 'horas',
      text: 'Dias mês',
      sort: true,
      formatter: (cellContent, row) => (
          <React.Fragment>
              {row.horas ? <>
                <div className="badge badge-soft-primary font-size-24">
                  <b>{row.horas * 30 / 24}</b>
                </div>
              </> : <>
                <div className="badge badge-soft-primary font-size-12">
                  <b>{row.horas * 30 / 24}</b>
                </div>
              </>}
          </React.Fragment>
      )
    }, {
      dataField: 'horas',
      text: 'Horas ano',
      sort: true,
      formatter: (cellContent, row) => (
          <React.Fragment>
              {row.horas ? <>
                <div className="badge badge-soft-danger font-size-24">
                  <b>{row.horas * 30 * 12}</b>
                </div>
              </> : <>
                <div className="badge badge-soft-danger font-size-12">
                  <b>{row.horas * 30 * 12}</b>
                </div>
              </>}
          </React.Fragment>
      )
    }, {
      dataField: 'horas',
      text: 'Dias ano',
      sort: true,
      formatter: (cellContent, row) => (
          <React.Fragment>
              {row.horas ? <>
                <div className="badge badge-soft-info font-size-24">
                  <b>{row.horas * 30 * 12 / 24}</b>
                </div>
              </> : <>
                <div className="badge badge-soft-info font-size-12">
                  <b>{row.horas * 30 * 12 / 24}</b>
                </div>
              </>}
          </React.Fragment>
      )
    }, {
      dataField: 'horas',
      text: '% ano',
      sort: true,
      formatter: (cellContent, row) => (
          <React.Fragment>
              <b>{parseFloat(((row.horas * 30 * 12) / 8760) * 100).toFixed(2)}%</b>
              <Progress color="info" value={((row.horas * 30 * 12) / 8760) * 100} >
              </Progress>
          </React.Fragment>
      )
    }, {
      dataField: 'id',
      isDummyField: true,
      text: "Opções",
      formatter: (cellContent, row) => (
        <React.Fragment>
            <Link 
                to="#" 
                onClick={() => this.abrirModalEdicao(row.id)}
                className="me-1 btn btn-info btn-rounded btn-sm"
                title="Registrar venda"
            >
                <i className="mdi mdi-pencil-box-multiple font-size-18"></i>
            </Link>
        </React.Fragment>
      ),
    }];

    const defaultSorted = [{
      dataField: 'categorias',
      order: 'asc'
    }];

    const pageOptions = {
      sizePerPage: 10,
      totalSize: this.state.productData.length, // replace later with size(customers),
      custom: true,
    }

    return (
      <React.Fragment>
        <ToastContainer />
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Horas" breadcrumbItems={this.state.breadcrumbItems} />
            <Input id="filtroCategoria" type="text" style={{ display: 'none' }}/>
            <div className="row">
                <div className="col-md-4">
                  <Link to="#" onClick={() => this.setFiltroCategoria('A iniciar')}>
                    <div className="card" style={this.boxSelecao('A iniciar')}>
                        <div className="card-body">
                            <div className="d-flex">
                            <div className="flex-1 overflow-hidden">
                                <h5>Horas ano</h5>
                                <h5 className="mb-0 font-size-24">8760</h5>
                            </div>
                            <div className="text-warning font-size-24">⏱️</div>
                            </div>
                        </div>
                    </div>
                  </Link>
                </div>
                <div className="col-md-4">
                  <Link to="#" onClick={() => this.setFiltroCategoria('Em andamento')}>
                    <div className="card" style={this.boxSelecao('Em andamento')}>
                        <div className="card-body">
                            <div className="d-flex">
                              <div className="flex-1 overflow-hidden">
                                  <h5>Horas mês</h5>
                                  <h5 className="mb-0 font-size-24">730</h5>
                              </div>
                              <div className="text-warning font-size-24">⏱️</div>
                            </div>
                        </div>
                    </div>
                  </Link>
                </div>
                <div className="col-md-4">
                  <Link to="#" onClick={() => this.setFiltroCategoria('Concluído')}>
                    <div className="card" style={this.boxSelecao('Concluído')}>
                        <div className="card-body">
                            <div className="d-flex">
                              <div className="flex-1 overflow-hidden">
                                  <h5>Dia ocupado</h5>
                                  <h5 className="mb-0 font-size-24">{this.state.total_iniciar} horas, {parseFloat(this.state.total_iniciar / 24 * 100).toFixed(2)}%</h5>
                              </div>
                              <div className="text-success font-size-24">⏱</div>
                            </div>
                        </div>
                    </div>
                  </Link>
                </div>
            </div>

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='usuarios_id'
                      columns={columns}
                      data={this.state.productData}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='usuarios_id'
                          columns={columns}
                          data={this.state.productData}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>

                              <Row className="mb-2">
                                <Col md="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <Row className="mb-3">
                                          <Col md={10}>
                                              <Input id="valorPesquisar" type="text" placeholder="Pesquisar" onChange={() => this.pesquisar()}/>
                                          </Col>
                                      </Row>
                                      
                                      <i className="search-box chat-search-box" />
                                    </div>
                                  </div>
                                </Col>
                                <Col sm="4">
                                  <div className="text-sm-end">
                                    <CustomProvider theme='dark'>
                                      <Stack direction="column" spacing={8} alignItems="flex-start">
                                        {/* <DateRangePicker
                                          ranges={PredefinedRanges}
                                          placeholder="Selecionar data"
                                          style={{ width: 300 }}
                                          id="calendario"
                                          value={this.state.mesAtual}
                                          onChange={ 
                                            event => {
                                              setTimeout(() => {
                                                this.setState({ mesAtual : event });
                                                this.buscarDados();
                                              }, 0);
                                            }
                                          }
                                        /> */}
                                      </Stack>
                                    </CustomProvider>
                                  </div>
                                </Col>
                                <div className="col-sm-4">
                                  <Row>
                                    <div className="text-sm-end">
                                      <button type="button" onClick={() => this.controlarModal()} className="btn-rounded mb-2 me-2 btn btn-success"><i className="mdi mdi-plus-thick me-1 font-size-18"></i> CADASTRAR</button>
                                    </div>
                                  </Row>
                                </div>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField='idusuarios'
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      ref={(n) => (this.node = n)}
                                      classes={
                                        "table align-middle table-nowrap table-hover"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />

                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>

        <Modal
          isOpen={this.state.modal_cadastro}
          toggle={this.controlarModal}
        >
          <ModalHeader toggle={() => this.setState({ modal_cadastro: false })}>
              Horas
          </ModalHeader>
          <ModalBody>
            <Row className="mb-3">
                <Label className="form-label">Categoria</Label>
                <Col md={12}>
                    <Input id="idCadastro" type="text" style={{ display: 'none' }}/>
                    <Input id="data_pagamento" type="text" style={{ display: 'none' }}/>
                    <Col md={12}>
                    <select id="categoria" className="form-control">
                        <option value="">Selecionar...</option>
                        <option value="Conjugal">Conjugal</option>
                        <option value="Emocional">Emocional</option>
                        <option value="Espiritual">Espiritual</option>
                        <option value="Família">Família</option>
                        <option value="Filhos">Filhos</option>
                        <option value="Financeiro">Financeiro</option>
                        <option value="Intelectual">Intelectual</option>
                        <option value="Profissíonal">Profissíonal</option>
                        <option value="Saúde">Saúde</option>
                        <option value="Servir">Servir</option>
                        <option value="Social">Social</option>
                    </select>
                </Col>
                </Col>  
            </Row>
            <Row className="mb-3">
                <Label className="form-label">Atividade</Label>
                <Col md={12} disabled>
                    <Input id="atividade" type="text"/>
                </Col>  
            </Row>
            <Row className="mb-3">
                <Label className="form-label">Quantas horas do seu dia você aplica nesta atividade?</Label>
                <Col md={12} disabled>
                    <Input id="horas" type="number" max={24}/>
                </Col>  
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              onClick={this.controlarModal}
              color="light"
              className="waves-effect"
            >
                Fechar
            </Button>
            <Button
              color="primary"
              className="btn btn-primary btn-block"
              onClick={() => this.cadastrarOuEditar()}
            >
              Salvar
            </Button>
          </ModalFooter>
        </Modal>

      </React.Fragment>
    )
  }
}

export default Horas;