import React, { Component } from "react";
import moment from 'moment';

import { Row, Col, Card, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Badge } from "reactstrap";
import { Link } from "react-router-dom";

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import { ToastContainer, toast } from 'react-toastify';

import ToolkitProvider from 'react-bootstrap-table2-toolkit';

import api from '../../../services/api';

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "../../../assets/scss/datatables.scss";

class Produtos extends Component {
    constructor(props) {
      super(props)
      this.state = {
        breadcrumbItems: [
          { title: "Menu", link: "/dashboard" },
          { title: "Produtos", link: "#" },
        ],
        page: 1,
        sizePerPage: 10,
        productData: [],
        acaoData: [],
        visible: false,
        modal_standard: false,
        modal_apagar: false,
        modal_acao: false,
        filtro: '',
        url: '/produtos',
        fazercontato: 0,
        emcontato: 0,
        agendadozoom: 0,
        zoomok: 0,
        linkenviado: 0,
        emnegociacao: 0,
        propostarecusada: 0,
        vendaextraordinaria: 0,
        descricao: false,
        preco: false,
        produtosTotal: 0
      }

      this.controlarModal = this.controlarModal.bind(this);
    };

    async buscarDados() {
      const options = this.getOptions();

      const { data } = await api.get(`${this.state.url}/executivo?filtro=`, options);

      this.setState({
        productData: data,
        produtosTotal: data.length
      });
    }

    pesquisar() {
      const valorPesquisa = this.getValueId('valorPesquisar');
      
      if (document.getElementsByClassName("page-link").length > 0) {
        document.getElementsByClassName("page-link")[0].click();
      }
      
      this.setState({
        filtro: valorPesquisa
      });

      this.buscarDados();
    }

    getOptions() {
        let obj = '';
        if (localStorage.getItem("authUser")) {
        obj = JSON.parse(localStorage.getItem("authUser"));
        }

        const options = {
        headers: {"Authorization" : `Bearer ${obj.token}`}
        }

        return options;
    }

    async controlarModalEdicao(idItem) {
        const options = this.getOptions();
        await api.get(`${this.state.url}/${idItem}`, options)
        .then(({ data })=> {

          this.controlarModal();

          setTimeout(() => {
              this.setValueId('idItem', idItem);
          }, 500);

          this.setState({
            descricao: data.descricao,
            nome: data.nome,
            preco: data.preco
          });
        });
    }

    cadastrarOuEditar() {
      const existeId = document.getElementById('idItem').value;

      if (existeId) {
        //this.editarItem();
      } else {
        this.cadastrarItem();
      }
    }

    async cadastrarItem() {
      try {
        const novoItem = this.getObjeto();
        if (!this.validarCampos(novoItem)) {
          return false;
        }
        const options = this.getOptions();
        await api.post(this.state.url, novoItem, options)
        .then(() => {
          this.controlarModal();
          toast.success('Item cadastrado.');
          this.buscarDados();
        });

      } catch (error) {
        toast.error('Não foi possível cadastrar o item.');
      }
    }

    getObjeto() {
      return {
        nome: this.getValueId('nome'),
        preco: this.getValueId('preco').replace(/[^0-9]/g,'').replace(/(\d{1,2})$/, '.$1'),
        descricao: this.getValueId('descricao'),
        ativo: this.getValueId('ativo')
      };
    }

    validarCampos(item) {
      const { nome, preco } = item;

      if (!nome) {
        toast.error('Campo nome obrigatório');
        return false;
      }

      if (!preco) {
        toast.error('Campo preço obrigatório');
        return false;
      }

      return true;
    }

    getValueId(nomeId) {
      return document.getElementById(nomeId).value;
    }

    setValueId(nomeId, valor) {
      document.getElementById(nomeId).value = valor;
    }

    controlarModal() {
      this.setState(prevState => ({
        modal_standard: !prevState.modal_standard
      }));
      this.removeBodyCss();
    }

    removeBodyCss() {
      document.body.classList.add("no_padding");
    }

    format(text) {
      return text.split('\n').map(str => <p>{str}</p>);
    }  

    componentDidMount() {
      this.buscarDados();
    }

  render() {

    const columns = [{
        dataField: 'updated_at',
        text: 'Ultima Atualização',
        sort: true,
        formatter: (cellContent, row) => (
            <React.Fragment>
                {moment(row.updated_at).format('DD/MM/YYYY HH:mm:ss')}
            </React.Fragment>
        )
    }, {
        dataField: 'nome',
        text: 'Produto',
        sort: true
    }, {
        dataField: 'preco',
        text: 'Preço',
        sort: true,
        formatter: (cellContent, row) => (
          <React.Fragment>
              R$ {parseFloat(row.preco).toLocaleString('pt-br', {minimumFractionDigits: 2})} 
          </React.Fragment>
        )
    }, {
      dataField: 'ativo',
      text: 'Ativo',
      sort: true,
      formatter: (cellContent, row) => (
        <Badge
          className={`font-size-12 bg-${row.ativo === 1 ? 'success' : 'danger'}`}
          color={`${row.ativo === 1 ? 'success' : 'danger'}`}
          pill
        >
          {row.ativo === 1 ? 'Sim' : 'Não'}
        </Badge>
      ),

    }, {
      dataField: 'id',
      isDummyField: true,
      text: "Opções",
      formatter: (cellContent, row) => (
        <React.Fragment>
            <Link 
                to="#" 
                onClick={() => this.controlarModalEdicao(row.id)} 
                className="me-3 btn btn-primary btn-rounded btn-sm"
                title="Atualizar cadastro"
            >
                <i className="ri-file-text-line font-size-18"></i>
            </Link>
        </React.Fragment>
      ),
    }];

    const defaultSorted = [{
      dataField: 'categorias',
      order: 'asc'
    }];

    const pageOptions = {
      sizePerPage: 10,
      totalSize: this.state.productData.length, // replace later with size(customers),
      custom: true,
    }

    return (
      <React.Fragment>
        <ToastContainer />
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Cadastro de Produtos" breadcrumbItems={this.state.breadcrumbItems} />
            <Row className="mb-3">
                <div className="col-md-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex">
                            <div className="flex-1 overflow-hidden">
                                <p className="text-truncate font-size-14 mb-2">Total de produtos</p>
                                <h4 className="mb-0">{this.state.produtosTotal}</h4>
                            </div>
                            <div className="text-primary"><i className="ri-dropbox-fill font-size-24"></i></div>
                            </div>
                        </div>
                    </div>
                </div>
            </Row>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='id'
                      columns={columns}
                      data={this.state.productData}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='id'
                          columns={columns}
                          data={this.state.productData}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField='idusuarios'
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      ref={(n) => (this.node = n)}
                                      classes={
                                        "table align-middle table-nowrap table-hover"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />

                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
        <Modal
          isOpen={this.state.modal_standard}
          toggle={this.controlarModal}
        >
          <ModalHeader toggle={() => this.setState({ modal_standard: false })}>
              Detalhes do produto
          </ModalHeader>
          <ModalBody>
            <Input id="idItem" type="text" style={{ display: 'none' }}/>
            {this.state.nome ? (<>
              <Row className="mb-3">
                <Label className="form-label">Nome <span style={{ fontWeight: 300 }}>{this.format(this.state.nome)}</span></Label>
              </Row>
            </>) : (<></>)} 
            {this.state.preco ? (<>
              <Row className="mb-3">
                <Label className="form-label">Preço <span style={{ fontWeight: 300 }}>{this.format(this.state.preco)}</span></Label>
              </Row>
            </>) : (<></>)}
            {this.state.descricao ? (<>
              <Row className="mb-3">
                <Label className="form-label">Descrição <span style={{ fontWeight: 300 }}>{this.format(this.state.descricao)}</span></Label>
              </Row>
            </>) : (<></>)}
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              onClick={this.controlarModal}
              color="light"
              className="waves-effect"
            >
              Fechar
          </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    )
  }
}

export default Produtos;