import React, { Component } from 'react';
import { Container, Row, Col, Button, Modal, ModalHeader, ModalBody, Label, Input, ModalFooter } from "reactstrap";
import { Link } from "react-router-dom";
import countdown from 'countdown';
import { ToastContainer, toast } from 'react-toastify';

//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';

import Avatar from 'react-avatar';

import api from '../../../services/api';

class Habitos extends Component {
    constructor(props) {
        super(props);
        this.state={
            breadcrumbItems : [
                { title : "Home", link : "/home" },
                { title : "Hábitos", link : "#" },
            ],
            nomeCompleto: '',
            avatar: '',
            username: '',
            habitosArray: [],
            departamentosArray: [],
            modal_standard: false,
        }

        this.controlarModal = this.controlarModal.bind(this);
    }

    controlarModal() {
        this.setState(prevState => ({
          modal_standard: !prevState.modal_standard
        }));
        this.removeBodyCss();

        this.buscarDepartamentos();
    }

    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    async cadastrarCheck(idHabito) {
        const options = this.getOptions();
  
        await api.post(`/habitos/check/`, { habitos_id : idHabito } ,options).then(() => {
            toast.success('Nunca foi sorte, parabéns pela conquista.');
            this.buscarHabitos();
        });
    }

    async cadastrarHabito() {
        const options = this.getOptions();
        const novoItem = this.getObjeto();

        if (!this.validarCampos(novoItem)) {
            return false;
          }
  
        await api.post(`/habitos`,novoItem , options).then(() => {
            this.controlarModal();
            toast.success('Hábito cadastrado.');
            this.buscarHabitos();
        });
    }

    getObjeto() {
        return {
            nome: document.getElementById('nome').value,
            habitos_departamentos_id: document.getElementById('departamento').value,
        }
    }

    validarCampos(item) {
        const { nome, habitos_departamentos_id } = item;
  
        if (!nome) {
          toast.error('Campo nome obrigatório');
          return false;
        }

        if (!habitos_departamentos_id) {
            toast.error('Escolha uma área para o seu hábito');
            return false;
          }
  
        return true;
    }

    async apagarHabito(idHabito) {
        const options = this.getOptions();
  
        await api.delete(`/habitos/check/${idHabito}`, options);
        this.buscarHabitos();
    }

    async buscarHabitos() {
        const options = this.getOptions();
  
        const { data } = await api.get(`/habitos/painel`, options);
  
        this.setState({
            habitosArray: data
        });
    }

    async buscarDepartamentos() {
        const options = this.getOptions();
  
        const { data } = await api.get(`/habitos/departamentos`, options);
  
        this.setState({
            departamentosArray: data
        });
    }

    getOptions() {
        let obj = '';
        if (localStorage.getItem("authUser")) {
        obj = JSON.parse(localStorage.getItem("authUser"));
        }

        const options = {
        headers: {"Authorization" : `Bearer ${obj.token}`}
        }

        return options;
    }

    carregarPrazoFinal() {
        const interval = 1000;

        setInterval(() => {
            countdown.setLabels(
                ' milissegundo| segundo| minuto| hora| dia| semana| mês| ano| década| século| milênio',
                ' milissegundos| segundos| minutos| horas| dias| semanas| meses| anos| décadas| séculos| milênios',
                ' e ',
                ', ',
                'agora');

            //document.getElementById('tempo').textContent=countdown(new Date('2022-10-14 04:00:00')).toString();
        }, interval);

        let username = "Logado";
        let nomeCompleto = '';
        let avatar = '';
        if (localStorage.getItem("authUser")) {
            const obj = JSON.parse(localStorage.getItem("authUser"));
            username = obj.nome.split(' ')[0];
            nomeCompleto = obj.nome;
            avatar = obj.avatar;
        }

        this.setState({
            username: username,
            nomeCompleto: nomeCompleto,
            avatar: avatar,
        });
    }

    componentDidMount() {
        this.buscarHabitos();
        this.carregarPrazoFinal();
    }

    render() {
        return (
            <React.Fragment>
                <ToastContainer />
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="Hábitos" breadcrumbItems={this.state.breadcrumbItems} />
                        <Row>
                            <Col className="col-12">
                                <div className="row">
                                    <Row className="mb-2">
                                        <Col md="4">
                                            <div className="search-box me-2 mb-2 d-inline-block">
                                                <div className="position-relative">
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm="8">
                                            <div className="text-sm-end">
                                            <Button
                                                type="button"
                                                color="primary"
                                                className="btn-rounded mb-2 me-2"
                                                onClick={this.controlarModal}
                                            >
                                                Cadastrar novo hábito
                                            </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="row">
                                    <div className="row">

                                        <div className="row">
                                            <div className="col-xl-12">
                                                <div className="text-center my-3">
                                                    <h1 className="text-warning">
                                                        PENDENTES
                                                    </h1>
                                                    <Link className="text-dark" to="#" onClick={this.controlarModal}>Cadastre um novo hábito...</Link> <br /><span className="text-dark" id="tempo"></span>
                                                </div>
                                            </div>
                                        </div>

                                        {this.state.habitosArray.map((habito) => {
                                            if (parseInt(habito.status) > 0) {
                                                return false;
                                            }
                                            return (
                                                <>
                                                    <div className="col-sm-6 col-xl-3">
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <div className="text-center">
                                                                    <Avatar 
                                                                        className="avatar-sm mt-2 mb-4" 
                                                                        name={this.state.nomeCompleto} 
                                                                        src={this.state.avatar} 
                                                                        size="100" 
                                                                        round={true} 
                                                                        maxInitials={2}
                                                                    />
                                                                    <div className="flex-1">
                                                                    <h5 className="text-truncate">
                                                                        <Link className="text-muted" to="#">
                                                                            {this.state.nomeCompleto} 
                                                                        </Link>
                                                                    </h5>
                                                                    <h5 className="text-dark">
                                                                        {habito.habitos}
                                                                    </h5>
                                                                    </div>
                                                                </div>
                                                                <div className="text-center row">
                                                                    <div className="col-6">
                                                                        <p className="text-muted mb-2">Sequencia</p>
                                                                        <h5>{habito.total > 0 ? `${habito.total} dias` : 'Novo hábito'}</h5>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <p className="text-muted mb-2">Categoria</p>
                                                                        <h5 id="tempo_restante">{habito.departamento}</h5>
                                                                    </div>
                                                                    <div className="d-grid mb-3">
                                                                        <button 
                                                                            className="btn btn-outline-warning btn-lg btn-block"
                                                                            onClick={() => this.cadastrarCheck(habito.habitos_id)}
                                                                        >
                                                                            <b>Pendente</b>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })}

                                        <div className="row">
                                            <div className="col-xl-12">
                                                <div className="text-center my-3">
                                                <h1 className="text-success">
                                                    CONCLUÍDOS
                                                </h1>
                                                </div>
                                            </div>
                                        </div>

                                        {this.state.habitosArray.map((habito) => {
                                            if (habito.status === '0') {
                                                return false;
                                            }
                                            return (
                                                <>
                                                    <div className="col-sm-6 col-xl-3">
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <div className="text-center">
                                                                    <Avatar 
                                                                        className="avatar-sm mt-2 mb-4" 
                                                                        name={this.state.nomeCompleto} 
                                                                        src={this.state.avatar} 
                                                                        size="100" 
                                                                        round={true} 
                                                                        maxInitials={2}
                                                                    />
                                                                    <div className="flex-1">
                                                                    <h5 className="text-truncate">
                                                                        <Link className="text-muted" to="#">
                                                                            {this.state.nomeCompleto} 
                                                                        </Link>
                                                                    </h5>
                                                                    <h5 className="text-dark">
                                                                        {habito.habitos}
                                                                    </h5>
                                                                    </div>
                                                                </div>
                                                                <div className="text-center row">
                                                                    <div className="col-6">
                                                                        <p className="text-muted mb-2">Sequencia</p>
                                                                        <h5>{habito.total > 0 ? `${habito.total} dias` : 'Novo hábito'}</h5>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <p className="text-muted mb-2">Categoria</p>
                                                                        <h5 id="tempo_restante">{habito.departamento}</h5>
                                                                    </div>
                                                                    {/* <div className="d-grid mb-3">
                                                                        <button 
                                                                            className="btn btn-outline-success btn-lg btn-block"
                                                                            onClick={() => this.apagarHabito(habito.habitos_id)}
                                                                        >
                                                                            <b>Concluído</b>
                                                                        </button>
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })}
                                    </div>
                                </div>
                            </Col>
                        </Row>    
                    </Container> 
                </div>
                <Modal
                    isOpen={this.state.modal_standard}
                    toggle={this.controlarModal}
                    >
                    <ModalHeader toggle={() => this.setState({ modal_standard: false })}>
                        Cadastro de Hábito
                    </ModalHeader>
                    <ModalBody>
                        <Row className="mb-3">
                            <Label className="form-label">Nome</Label>
                            <Col md={12}>
                                <Input id="idItem" type="text" style={{ display: 'none' }}/>
                                <Input id="nome" type="text"/>
                            </Col>  
                        </Row>

                        <Row className="mb-3">
                            <Label className="form-label">Qual o área do hábito?</Label>
                            <Col md={12}>
                                <select id="departamento" className="form-control">
                                    <option value="">Selecionar</option>
                                    {this.state.departamentosArray.map((departamento) => {
                                        return (
                                            <option value={departamento.id}>{departamento.nome}</option>
                                        );
                                    })}
                                    
                                </select>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                        type="button"
                        onClick={this.controlarModal}
                        color="light"
                        className="waves-effect"
                        >
                        Fechar
                    </Button>
                        <Button
                        type="button"
                        color="primary" className="waves-effect waves-light"
                        onClick={() => this.cadastrarHabito()}
                        >
                        Salvar
                    </Button>
                    </ModalFooter>
                    </Modal>
            </React.Fragment>
        );
    }
}

export default Habitos;